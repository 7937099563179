import React, { useState } from "react";
import DatePicker from "react-datepicker";

const generateUrl = (params) =>
  `https://www.dwellsocial.com/landing?${Object.entries(params)
    .filter((pair) => pair[1])
    .map((pair) => pair.map(encodeURIComponent))
    .map((pair) => pair.join("="))
    .join("&")}`;

const InputWrapper = ({ children }) => (
  <dd>
    <div>
      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          {children}
        </div>
      </div>
    </div>
  </dd>
);

const Input = ({ onChange, id, label, placeholder, value }) => (
  <label>
    {label}
    <input
      id={id}
      onChange={onChange}
      value={value}
      type="text"
      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md text-xs border-gray-300"
      placeholder={placeholder}
    />
  </label>
);

const Select = ({ onChange, options, id, label, value }) => (
  <label>
    {label}
    <br />
    <select
      id={id}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    >
      <option value=""></option>
      {options.map((pair) => (
        <option key={pair[0]} value={pair[0]}>
          {pair[1]}
        </option>
      ))}
    </select>
  </label>
);

const CheckBox = ({ onChange, id, label, placeholder, value }) => {
  return (
    <div className="form-check ml-4">
      <label
        className="form-check-label inline-block text-gray-800"
        htmlFor={id}
      >
        {label}
        <br />
        <input
          type="checkbox"
          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          name={id}
          value=""
          onChange={onChange}
          checked={value ? "checked" : null}
        />
      </label>
    </div>
  );
};

const DateSelect = ({ label, onChange, value }) => (
  <label>
    {label}
    <br />
    <DatePicker onChange={onChange} selected={value} />
  </label>
);

const extractDate = (date) =>
  date && new Date(Date.parse(date)).toISOString().split("T")[0];

export default function LandingPageCreator({ restaurants, eventLocations }) {
  const [campaign, setCampaign] = useState("");
  const [starts, setStarts] = useState("");
  const [ends, setEnds] = useState("");
  const [runOn, setRunOn] = useState("");
  const [restaurant, setRestaurant] = useState("");
  const [eventLocation, setEventLocation] = useState("");

  const [near, setNear] = useState(true);

  const landingPageURL = generateUrl({
    starts: starts && ends && !runOn ? extractDate(starts) : undefined,
    ends: starts && ends && !runOn ? extractDate(ends) : undefined,
    run_on: extractDate(runOn),
    restaurant: restaurant,
    event_location: near ? undefined : eventLocation,
    near: near ? eventLocation : undefined,
    utm_campaign: campaign,
  });

  const setValue = (stateSetter) => (e) =>
    stateSetter(
      e.target.type === "checkbox" ? e.target.checked : e.target.value,
    );

  return (
    <div>
      <dt className="group flex items-center">
        New (on platform) Landing Page
      </dt>

      <dd>
        <div className="text-xl m-3">
          <a href={landingPageURL} target="_blank">
            {landingPageURL}
          </a>
        </div>
      </dd>

      <InputWrapper>
        <Input
          id="campaign"
          label="Campaign ID"
          onChange={setValue(setCampaign)}
          value={campaign}
        />
      </InputWrapper>

      <InputWrapper>
        <Select
          id="restaurant"
          label="Restaurant"
          onChange={setValue(setRestaurant)}
          value={restaurant}
          options={restaurants}
        />
      </InputWrapper>

      <InputWrapper>
        <Select
          id="event_locatino"
          label="Event Location"
          onChange={setValue(setEventLocation)}
          value={eventLocation}
          options={eventLocations}
        />
        <CheckBox
          id="near"
          label="Within Radius"
          onChange={setValue(setNear)}
          value={near}
        />
      </InputWrapper>

      <InputWrapper>
        <DateSelect
          label="Starts - range"
          onChange={setStarts}
          value={starts}
        />
      </InputWrapper>

      <InputWrapper>
        <DateSelect label="Ends - range" onChange={setEnds} value={ends} />
      </InputWrapper>

      <InputWrapper>
        <DateSelect
          label="Run On - specific date"
          onChange={setRunOn}
          value={runOn}
        />
      </InputWrapper>
    </div>
  );
}
