import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";

import { toMoney } from "../../helpers";
import styles from "./styles";

const Subtotals = ({ net, refund, serviceFee, subtotal, tax, tip }) => (
  <View style={styles.rowRow}>
    <Text style={styles.rowBold}>SUBTOTALS:</Text>
    <Text style={styles.rowSubtotal}>{toMoney(subtotal)}</Text>
    <Text style={styles.rowRefund}>{toMoney(refund)}</Text>
    <Text style={styles.rowTax}>{toMoney(tax)}</Text>
    <Text style={styles.rowTip}>{toMoney(tip)}</Text>
    <Text style={styles.rowServiceFee}>{toMoney(serviceFee)}</Text>
    <Text style={styles.rowTotal}>{toMoney(net)}</Text>
  </View>
);

Subtotals.propTypes = {
  net: PropTypes.number,
  refund: PropTypes.number,
  serviceFee: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  tip: PropTypes.number,
};
export default Subtotals;
