import { Button, List, ListItem, ListItemText } from "@material-ui/core";
import SmsIcon from "@material-ui/icons/Sms";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Section from "../../backoffice/Section";
import timeAgo from "../../helpers/ago";
import { getEmails } from "../../helpers/api";
import Email from "./email";

const toDbDate = (date) =>
  date.toISOString().slice(0, 19).replace("T", " ").split(" ")[0];

const fromMeta = ({ ts, body, state, subject, email, opens, clicks, _id }) => ({
  date: new Date(ts * 1000),
  body,
  state,
  subject,
  email,
  opens,
  clicks,
  id: _id,
  url: `https://mandrillapp.com/activity/content?id=${toDbDate(
    new Date(ts * 1000),
  )}_${_id}`,
});

const EmailList = ({ id }) => {
  const [emails, setEmails] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (id) {
      setLoaded(false);

      getEmails(id)
        .then(({ data }) => setEmails(data))
        .catch((error) => console.log("error w/ email provider", error))
        .finally(() => setLoaded(true));
    }
  }, [id]); // eslint-disable-line

  if (!loaded) return <h3>Loading...</h3>;

  return (
    <List>
      {emails.map(fromMeta).map((email) => (
        <ListItem key={email.id}>
          <ListItemText
            primary={`${email.subject}`}
            secondary={timeAgo(email.date)}
          />
          <ListItemText primary={<Email body={email.body} url={email.url} />} />
          <ListItemText
            primary={`${email.opens}/${email.clicks}`}
            secondary={"opens/clicks"}
          />
        </ListItem>
      ))}
    </List>
  );
};

const Emails = ({ id }) => {
  const [shown, setShown] = useState(false);
  const setShownTrue = () => setShown(true);

  console.log("SHOWN 3", shown);
  return (
    <Section
      title="Emails"
      subtitle="Mandrill Outbound"
      icon={SmsIcon}
      loaded={true}
    >
      {shown ? (
        <EmailList id={id} />
      ) : (
        <Button variant="text" onClick={setShownTrue}>
          Load Emails
        </Button>
      )}
    </Section>
  );
};

Emails.propTypes = {
  id: PropTypes.any,
};

export default Emails;
