import PropTypes from "prop-types";
import React from "react";
import Search from "./search";

const renderer = (option) => `${option.name} <${option.email}>`;

const UserSearch = ({ onChange }) => (
  <Search
    onChange={onChange}
    renderer={renderer}
    source="api/admin/users"
    fields={["id", "name", "email", "phone"]}
  />
);

UserSearch.propTypes = {
  onChange: PropTypes.func,
};
export default UserSearch;
