import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { search } from "../../helpers/api";

const Search = ({
  onChange,
  filters,
  queryTransform,
  renderer,
  source,
  fields,
  sort,
}) => {
  const [error, setError] = useState();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [data, setData] = useState([]);

  const onValueChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue?.id);
  };

  const onInputChange = (event, newInputValue) => setInputValue(newInputValue);
  const selected = (option, value) => option.id === value.id;
  const deadFilter = (x) => x;

  const performSearch = (q, onSuccess, onError) =>
    search(source, q, { queryTransform, fields, filters, sort })
      .then(({ data }) => onSuccess(data))
      .catch((error) => onError(error));

  useEffect(() => {
    if (inputValue) performSearch(inputValue, setData, setError);
  }, [inputValue]); // eslint-disable-line

  return (
    <>
      <Autocomplete
        value={value}
        onChange={onValueChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        options={data}
        getOptionLabel={renderer}
        getOptionSelected={selected}
        style={{ width: 400 }}
        filterOptions={deadFilter}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Search ${source}`}
            variant="outlined"
          />
        )}
      />

      {error ? <p>{error}</p> : ""}
    </>
  );
};

Search.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  queryTransform: PropTypes.func,
  renderer: PropTypes.func,
  source: PropTypes.string,
  fields: PropTypes.array,
  filters: PropTypes.object,
  sort: PropTypes.any,
};

export default Search;
