import React from "react";

const ProducedFilter = ({ producedFilter, setProducedFilter, inspect }) => {
  const onChange = (e) => {
    setProducedFilter(e.target.value);
  };

  return (
    <div className="ml-2">
      <h3>Produced Filter</h3>
      <select value={producedFilter || ""} onChange={onChange}>
        <option value=""></option>
        <option value="produced">Produced</option>
        <option value="unproduced">Unproduced</option>
      </select>
    </div>
  );
};

export default ProducedFilter;
