import React, { useState } from "react";

const LabeledRangeInput = ({
  id,
  name,
  min,
  max,
  value,
  step,
  onChange,
  label,
}) => (
  <div className="m-2">
    <label htmlFor={id}>
      {label} (max: ${max}): ${value || 0.0}{" "}
    </label>
    <br />
    <input
      type="range"
      id={id}
      name={name}
      min={min || 0.0}
      max={max}
      value={value}
      step={step || 0.01}
      onChange={onChange}
    />
  </div>
);

const RefundWizard = ({ order_id, max_ds, max_restaurant }) => {
  const [dsValue, setDsValue] = useState(0.0);
  const [restaurantValue, setRestaurantValue] = useState(0.0);
  const changeDs = (e) => setDsValue(parseFloat(e.target.value));
  const changeRestaurant = (e) =>
    setRestaurantValue(parseFloat(e.target.value));
  const total = dsValue + restaurantValue;

  return (
    <>
      <input type="hidden" name="order_id" value={order_id} />
      <input type="hidden" name="invoiceable" value={true} />
      <input type="hidden" name="bypass_closed" value={true} />
      <input type="hidden" name="quantity" value={1} />

      <LabeledRangeInput
        id="ds_refund_price"
        name="line_item[ds_refund_price]"
        min={0.0}
        max={max_ds}
        value={dsValue}
        onChange={changeDs}
        label="DwellSocial Refund"
      />
      <LabeledRangeInput
        id="restaurant_refund_price"
        name="line_item[restaurant_refund_price]"
        min={0.0}
        max={max_restaurant}
        value={restaurantValue}
        onChange={changeRestaurant}
        label="Restaurant Refund"
      />

      {total > 0.0 ? (
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Refund ${total}
        </button>
      ) : null}
    </>
  );
};

export default RefundWizard;
