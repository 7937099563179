import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

const Blank = ({ count }) => (
  <>
    {Array(count)
      .fill(0)
      .map((x, i) => (
        <View style={styles.billableRow} key={`blank_${i}`}>
          <Text style={styles.billableDescription}>-</Text>
          <Text style={styles.billableQuantity}>-</Text>
          <Text style={styles.billableRate}>-</Text>
          <Text style={styles.billableAmount}>-</Text>
        </View>
      ))}
  </>
);

Blank.propTypes = {
  count: PropTypes.number,
};

export default Blank;
