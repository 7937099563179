import exporter from "./index";

export const splitDate = (object, field) => {
  const date = object[field];
  if (!date) return [];

  const dt = new Date(date);
  const pad = (val) => (`${val}`.length === 1 ? `0${val}` : `${val}`);

  const get = (obj) => (field) => obj[`get${field}`]();

  return [
    `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`,

    ["Hours", "Minutes", "Seconds"].map(get(dt)).map(pad).join(":"),
  ];
};

// CSV EXPORT
const query = (project) => ({
  filter: { project_id: project.id },
  pagination: { page: 1, perPage: 10000 },
  sort: { field: "created_at", order: "ASC" },
});

const membershipsExporter = exporter({
  filename: "members",
  source: "project_memberships",
  fields: [
    "user_id",
    "project_id",
    "email",
    "name",
    "phone",
    "address",
    "status",
    "completed_at",
    "created_at",
    "lat",
    "lng",
    "created_at_date",
    "created_at_time",
    "updated_at_date",
    "updated_at_time",
    "completed_at_date",
    "completed_at_time",
    "redeemed_at_date",
    "redeemed_at_time",
  ],
  labels: [
    "MemberId",
    "ProjectId",
    "Email",
    "Name",
    "Phone",
    "Address",
    "Status",
    "CompletedAt",
    "JoinedAt",
    "Latitude",
    "Longitude",
    "CreatedDate",
    "CreatedTime",
    "UpdatedDate",
    "UpdatedTime",
    "CompletedDate",
    "CompletedTime",
    "RedeemedAtDate",
    "RedeemedAtTime",
  ],
  query,
  transformer: (item) => {
    const created = splitDate(item, "created_at");

    const updated = splitDate(item, "updated_at");

    const completed = splitDate(item, "completed");

    const redeemed = splitDate(item, "redeemed_at");

    return {
      ...item,
      created_at: (item.created_at || "").split("T")[0],
      created_at_date: created[0],
      created_at_time: created[1],
      updated_at_date: updated[0],
      updated_at_time: updated[1],
      completed_at_date: completed[0],
      completed_at_time: completed[1],
      redeemed_at_date: redeemed[0],
      redeemed_at_time: redeemed[1],
    };
  },
});
export default membershipsExporter;
