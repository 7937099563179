import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";

import { toMoney } from "../../helpers";
import styles from "./styles";

const Order = ({
  id,
  name,
  net,
  refund,
  service_fee,
  serviceFee,
  subtotal,
  tax,
  tip,
}) => (
  <View style={styles.rowRow}>
    <Text style={styles.rowId}>
      {id} - {name}
    </Text>
    <Text style={styles.rowSubtotal}>{toMoney(subtotal)}</Text>
    <Text style={styles.rowRefund}>{toMoney(refund)}</Text>
    <Text style={styles.rowTax}>{toMoney(tax)}</Text>
    <Text style={styles.rowTip}>{toMoney(tip)}</Text>
    <Text style={styles.rowServiceFee}>
      {toMoney(service_fee || serviceFee)}
    </Text>
    <Text style={styles.rowTotal}>{toMoney(net)}</Text>
  </View>
);

Order.propTypes = {
  items: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.string,
  net: PropTypes.any,
  refund: PropTypes.any,
  service_fee: PropTypes.any,
  serviceFee: PropTypes.any,
  subtotal: PropTypes.any,
  tax: PropTypes.any,
  tip: PropTypes.any,
};
export default Order;
