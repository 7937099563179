import { UploadIcon } from "@heroicons/react/outline";
import { DirectUpload } from "@rails/activestorage";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const FileUpload = ({ method, objectName, storedImage, multipleFiles }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      setFile(file);
      setPreview(URL.createObjectURL(file));
      uploadFile(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const uploadFile = (file) => {
    const upload = new DirectUpload(
      file,
      "/rails/active_storage/direct_uploads",
      {
        directUploadWillStoreFileWithXHR: (xhr) => {
          xhr.setRequestHeader("x-amz-acl", "public-read");
        },
      },
    );
    upload.create((error, blob) => {
      if (error) {
        console.log("Image Error:", error);
      } else {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = multipleFiles
          ? `${objectName}[${method}][]`
          : `${objectName}[${method}]`;
        document.querySelector("form").appendChild(hiddenField);
      }
    });
  };

  useEffect(
    () => () => {
      // revoke the data URI to avoid memory leaks
      preview && URL.revokeObjectURL(preview);
    },
    [preview],
  );

  return (
    <div {...getRootProps()} className="mt-1 sm:mt-0 sm:col-span-2">
      <div className="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          {file ? (
            <div
              className={
                "h-36 w-36 mx-auto flex" + (isDragActive ? " opacity-50" : "")
              }
            >
              {preview ? (
                <img src={preview} className="rounded self-center" />
              ) : (
                <div className="self-center mx-auto">{file.name}</div>
              )}
            </div>
          ) : storedImage && !isDragActive ? (
            <img
              src={storedImage}
              className="mx-auto mb-4 w-36 rounded self-center"
            />
          ) : (
            <UploadIcon
              className={
                isDragActive
                  ? "mx-auto mb-4 h-36 w-36 text-green-200"
                  : "mx-auto mb-4 h-36 w-36 text-gray-100"
              }
            />
          )}
          <div className="flex text-sm text-gray-600">
            <span className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
              Upload a file
            </span>
            <input {...getInputProps({ multiple: false })} />
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
