import React from "react";

const RestaurantFilter = ({
  restaurants,
  restaurantFilter,
  setRestaurantFilter,
  inspect,
}) => {
  const onChange = (e) => {
    inspect("restaurant", e.target.value);
    setRestaurantFilter(e.target.value);
  };

  return (
    <div className="ml-2">
      <h3>Restaurant Filter</h3>
      <select value={restaurantFilter} onChange={onChange}>
        <option value=""></option>
        {restaurants.map((r, i) => (
          <option key={i} value={r.id}>
            {r.businessName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RestaurantFilter;
