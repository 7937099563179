import {
  fromCustomerBalanceResponse,
  toCustomerBalanceParams,
  normalizeStartDate,
  normalizeEndDate,
} from "./api";

it("inverts the outbound credit balance for the api expectation of credits = negative and debits = positive", () => {
  expect(toCustomerBalanceParams({ user_id: 1, amount: 25 })).toEqual({
    user_id: 1,
    amount: -25,
  });
});

it("inverts the inbound credit balance for the credits api", () => {
  expect(fromCustomerBalanceResponse({ credit_balance: -25 })).toEqual({
    credit_balance: 25,
  });
});

describe("DateRange helpers", () => {
  it("quantizes start_date to beginning of local day", () => {
    expect(
      normalizeStartDate("Thu, 24 Aug 2023 21:00:00.000000000 UTC +00:00"),
    ).toEqual("2023-08-24 05:00:00");
  });

  it("quantizes end_date to end of local day", () => {
    expect(
      normalizeEndDate("Thu, 24 Aug 2023 21:00:00.000000000 UTC +00:00"),
    ).toEqual("2023-08-25 04:59:59");
  });
});
