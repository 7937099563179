import React from "react";

const EventLocationFilter = ({
  eventLocations,
  eventLocationFilter,
  setEventLocationFilter,
  inspect,
}) => {
  const onChange = (e) => {
    inspect("eventLocations", e.target.value);
    setEventLocationFilter(e.target.value);
  };

  return (
    <div className="ml-2">
      <h3>Event Location Filter</h3>
      <select value={eventLocationFilter} onChange={onChange}>
        <option value=""></option>
        {eventLocations.map((r, i) => (
          <option key={i} value={r.id}>
            {r.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EventLocationFilter;
