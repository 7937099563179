import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";

export const showMonth = (date) =>
  new Date(date).toLocaleString("en-us", {
    year: "numeric",
    month: "short",
  });

const MonthPicker = ({ month, setMonth }) => {
  const selectedDate = month ? month : null;

  const Button = forwardRef(({ onClick }, ref) => (
    <button className="btn-tertiary" onClick={onClick} ref={ref}>
      {showMonth(month)}
    </button>
  ));

  const handleChange = (date) => setMonth(date);

  return (
    <div className="mb-4">
      <DatePicker
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        onChange={handleChange}
        customInput={<Button />}
        selected={selectedDate}
      />
    </div>
  );
};

MonthPicker.propTypes = {
  month: PropTypes.instanceOf(Date),
  setMonth: PropTypes.func,
};

export default MonthPicker;
