import { TruckIcon } from "@heroicons/react/outline";
import React from "react";
import Driver from "./Driver";

const DriverWrapper = ({ driver, isLoading, error, eventId }) => {
  return (
    <div>
      <dt>Driver</dt>
      <dd>
        {driver ? (
          <Driver driver={driver} eventId={eventId} />
        ) : isLoading ? (
          <div className="flex flex-row items-center text-gray-500">
            <TruckIcon className="w-4 h-4 mr-1 animate-spin" />
            Loading...
          </div>
        ) : error ? (
          <span className="text-red-500">Error: Could not load driver</span>
        ) : (
          <span className="text-gray-500">No driver assigned</span>
        )}
      </dd>
    </div>
  );
};

export default DriverWrapper;
