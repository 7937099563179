import { Text, View } from "@react-pdf/renderer";
import React from "react";
import styles from "./styles";

const Footer = () => (
  <View style={styles.footer}>
    <Text style={styles.footerTitle}>We appreciate your business!</Text>
  </View>
);

export default Footer;
