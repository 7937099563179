import PropTypes from "prop-types";
import React from "react";

const Table = ({ children }) => (
  <div className="flex flex-col">
    <div className="-my-2 overflow-x-auto -mx-8">
      <div className="py-2 align-middle inline-block min-w-full px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
          <table className="backoffice-table">{children}</table>
        </div>
      </div>
    </div>
  </div>
);

const Row = ({ values }) => (
  <tr>
    {(values || []).map((value, i) => (
      <td key={i} className="text-left">
        {value}
      </td>
    ))}
  </tr>
);

const DataTable = ({ headers, rows, title, summarize }) => {
  return (
    <>
      <Table>
        <thead>
          {title ? (
            <tr>
              <th colSpan={headers.length} className="font-heavy">
                {title}
              </th>
            </tr>
          ) : null}
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <Row key={i} values={row} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

DataTable.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.array,
  rows: PropTypes.array,
  summarize: PropTypes.bool,
};

export default DataTable;
