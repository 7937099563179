import PropTypes from "prop-types";
import React from "react";
import DataPoint from "./DataPoint";
import DataTable from "./DataTable";
import { daysOfMonth, groupByWeekDay, schedulableDaysOfMonth } from "./dates";
import { showMonth } from "./MonthPicker";

// Why?
const MONTHLY_GOAL = 200;

const forStatus = (proposals, status) =>
  proposals.filter((proposal) => status === proposal.status);

const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const MonthSummary = ({ month, proposals }) => {
  const countOfDays = daysOfMonth(month).length;
  const countOfSchedulableDays = schedulableDaysOfMonth(month).length;

  const pendingProposals = forStatus(proposals, "pending");
  const acceptedProposals = forStatus(proposals, "accepted");
  const rejectedProposals = forStatus(proposals, "rejected");

  const weekDayProposals = groupByWeekDay(proposals);
  const weekDayProposalCounts = WEEKDAYS.map(
    (day) => (weekDayProposals[day] || []).length,
  );

  return (
    <div className="well">
      <div className="well-header">
        <div className="flex items-center">
          <div className="flex-grow">
            <h2>{showMonth(month)} Calendar</h2>
          </div>
        </div>
      </div>
      <div className="well-body">
        <div className="flex items-center">
          <DataPoint label="Goal for scheduled groups" value={MONTHLY_GOAL} />
          <DataPoint label="Schedulable Days" value={countOfSchedulableDays} />
          <DataPoint label="Total Days" value={countOfDays} />
        </div>
        <div className="flex items-center">
          <DataPoint
            label="Pending Proposals"
            value={pendingProposals.length}
          />
          <DataPoint
            label="Accepted Proposals"
            value={acceptedProposals.length}
          />
          <DataPoint
            label="Rejected Proposals"
            value={rejectedProposals.length}
          />
          <DataPoint label="Total Proposals" value={proposals.length} />
        </div>
        <div className="flex items-center">
          <DataTable
            title="WeekDay Proposal Counts"
            headers={WEEKDAYS}
            rows={[weekDayProposalCounts]}
          />
        </div>
      </div>
    </div>
  );
};

MonthSummary.propTypes = {
  month: PropTypes.instanceOf(Date),
};

export default MonthSummary;
