import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Section from "../../backoffice/Section";
import timeAgo from "../../helpers/ago";
import { getCustomerBalance } from "../../helpers/api";

const UserCreditButton = () => "credit_button";

const Field = ({ icon, label, text }) => (
  <ListItem>
    {icon ? (
      <>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </>
    ) : (
      <ListItemText primary={label} secondary={text} />
    )}
  </ListItem>
);

Field.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.any,
  label: PropTypes.string,
};

const flexContainer = {
  display: "flex",
  flexDirection: "column",
  padding: 0,
};
const toDate = (dt) => new Date(dt);

const getMonth = (date) =>
  new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);

const toDateString = (date) =>
  date ? getMonth(toDate(date)) + " " + toDate(date).getDate() : null;

const Profile = ({ user }) => {
  // eslint-disable-line
  const [creditBalance, setCreditBalance] = useState("n/a");

  useEffect(() => {
    if (user?.id) {
      getCustomerBalance(user.id).then(({ data }) =>
        setCreditBalance(data.credit_balance),
      );
    }
  }, [user?.id]); // eslint-disable-line

  if (!user) return null;

  const address = [
    [user.street, user.city, user.state].filter((i) => i).join(", "),
    user.postal_code,
  ]
    .filter((i) => i)
    .join(" ");

  return (
    <Section
      icon={InfoIcon}
      title="Profile"
      loaded={true}
      subtitle={
        <>
          {`Joined ${timeAgo(user.created_at)}`}{" "}
          <a href={`/users/${user.id}/edit`}>Edit</a>
        </>
      }
    >
      <List aria-label="profile" style={flexContainer}>
        <Field icon={<PersonIcon />} text={user.name} />
        <Field icon={<EmailIcon />} text={user.email} />
        <Field icon={<PhoneIcon />} text={user.phone} />
        <Field icon={<HomeIcon />} text={address} />
        <Field
          label={"Confirmed"}
          text={toDateString(user.confirmed_at) || "unconfirmed"}
        />

        <Field
          label={"StripeId"}
          text={user.stripe_id ? `${user.stripe_id}` : "no stripe record"}
        />

        <Field
          label="Credit Balance"
          icon={<AddIcon />}
          text={
            <a href={`/users/${user.id}/credit_transactions`}>
              Manage Credits Balance: ${`${creditBalance}`}
            </a>
          }
        />
      </List>
    </Section>
  );
};

Profile.propTypes = {
  user: PropTypes.object,
};

export default Profile;
