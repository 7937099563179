import React, { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const DeliveryZonePicker = ({
  selectId,
  deliveryZones,
  deliveryZoneId,
  selectName,
}) => {
  const [zoneId, setZoneId] = useState(deliveryZoneId || "");

  const onChange = (e) => setZoneId(e.target.value);

  const onCopy = (e) => {
    e.preventDefault();

    if (!zoneId) {
      return null;
    }

    const select = document.getElementById(selectId);

    const optionSelector = `#${selectId} option`;

    const selectValues = deliveryZones.find(
      (ar) => `${ar.id}` == `${zoneId}`,
    ).eventLocationIds;

    /* Iterate options of select element */
    for (const option of document.querySelectorAll(optionSelector)) {
      /* Parse value to integer */
      const value = Number.parseInt(option.value);

      /* If option value contained in values, set selected attribute */
      if (selectValues.indexOf(value) !== -1) {
        option.setAttribute("selected", "selected");
      } else {
        /* Otherwise ensure no selected attribute on option */
        option.removeAttribute("selected");
      }
    }
  };

  return (
    <>
      <select
        name={selectName}
        id="proposal_delivery_zone_id"
        onChange={onChange}
        value={zoneId}
        label="Delivery Zone"
      >
        <option value="">Choose Delivery Zone</option>
        {deliveryZones.map((dz) => (
          <option key={dz.id} value={dz.id}>
            {dz.name}
          </option>
        ))}
      </select>
      {zoneId ? (
        <>
          <br />
          <br />
        </>
      ) : null}
      <button
        onClick={onCopy}
        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
          ${
            zoneId
              ? "bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              : "bg-grey-600"
          }`}
        {...(!zoneId ? { disabled: true } : {})}
      >
        <ArrowLeftIcon className="h-5" /> Copy ELs
      </button>
    </>
  );
};

export default DeliveryZonePicker;
