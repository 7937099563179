import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import ReportDateRangePicker from "./Reports/helpers/ReportDateRangePicker";
import Invoice from "./Reports/Invoice";
import Kitchen from "./Reports/Kitchen";
import Orders from "./Reports/Orders";
import ChitList from "./Reports/ChitList";
import {
  getChits,
  getInvoiceableEventIds,
  getAllEventIds,
} from "../helpers/api";
import Spinner from "../backoffice/Spinner";

const ReportLink = ({ name, shouldRender, loadData, loaderKey, render }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(); //{chits:[]})

  const doneLoading = () => setLoading(false);

  useEffect(() => {
    setLoading(true);
    loadData().then(setData).then(doneLoading);
  }, loaderKey);

  if (loading) {
    return <span>Loading {name}...</span>;
  }

  if (shouldRender ? !shouldRender(data) : !data?.length) {
    return <span>No data for date range</span>;
  }

  return render(data);
};

const ChitLink = ({ name, startDate, endDate, perPage }) => (
  <ReportLink
    name={name || "Chits"}
    shouldRender={(data) => data?.chits?.length}
    loadData={() => getChits(startDate, endDate, perPage)}
    loaderKey={[startDate, endDate]}
    render={(data) => (
      <ChitList {...data} title={`${name}.PDF`} perPage={perPage} />
    )}
  />
);

const KitchenLink = ({ name, startDate, endDate, perPage }) => {
  const [loading, setLoading] = useState(false);
  const startLoading = () => setLoading(true);
  const doneLoading = () => setLoading(false);

  // const loadKitchen = (data) => {
  //   startLoading()
  //   return Kitchen(data, doneLoading)
  // }

  return (
    <>
      <ReportLink
        name={name}
        loadData={() => getAllEventIds(startDate, endDate)}
        loaderKey={[startDate, endDate]}
        render={(data) => (
          <button
            onClick={(event) =>
              startLoading() || Kitchen(data, doneLoading)(event)
            }
          >
            KITCHEN.CSV
          </button>
        )}
      />
      {loading ? <span>...loading Products</span> : null}
    </>
  );
};
const OrdersLink = ({ name, startDate, endDate, perPage }) => {
  const [loading, setLoading] = useState(false);
  const startLoading = () => setLoading(true);
  const doneLoading = () => setLoading(false);

  // const loadOrders = (data) =>  {
  //   startLoading()
  //   return Orders(data, doneLoading)
  // }

  return (
    <>
      <ReportLink
        name={name}
        loadData={() => getAllEventIds(startDate, endDate)}
        loaderKey={[startDate, endDate]}
        render={(data) => (
          <button
            onClick={(event) =>
              startLoading() || Orders(data, doneLoading)(event)
            }
          >
            ORDERS.CSV
          </button>
        )}
      />

      {loading ? <span>...loading Orders</span> : null}
    </>
  );
};

const InvoiceLinks = ({ name, startDate, endDate, perPage }) => {
  const InvoiceLink = (id) => <Invoice key={id} id={id} />;

  return (
    <ReportLink
      name={name}
      loadData={() => getInvoiceableEventIds(startDate, endDate)}
      loaderKey={[startDate, endDate]}
      render={(eventIds) => (
        <ul>
          {eventIds.map((id) => (
            <li key={id}>
              <Invoice key={id} id={id} style={{ marginLeft: 5 }} />
            </li>
          ))}
        </ul>
      )}
    />
  );
};

const DayReports = (props) => {
  const [range, setRange] = useState();

  const updateRange = ({ startDate, endDate }) => {
    setRange({ startDate, endDate });
  };

  return (
    <>
      <ReportDateRangePicker onSelect={updateRange} />
      <hr />
      <div>
        <h2>
          <strong>Download Reports</strong>
        </h2>
        {!range ? (
          <span>No Date Range Selected</span>
        ) : (
          <ul>
            <li>
              <ChitLink name="Chits" {...range} perPage={4} />
            </li>
            <li>
              <ChitLink name="FullPageChits" {...range} perPage={1} />
            </li>
            <li>
              <KitchenLink name="Kitchen" {...range} />
            </li>
            <li>
              <OrdersLink name="Orders" {...range} />
            </li>
            <li>
              <InvoiceLinks name="Invoice" {...range} />
            </li>
          </ul>
        )}
      </div>
    </>
  );
};
DayReports.propTypes = {};

export default DayReports;
