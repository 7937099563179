export const localizedDate = (date, withTime = false) => {
  if (!date) return null;

  const dt = new Date(Date.parse(date));

  return [withTime ? dt.toLocaleTimeString() : null, dt.toLocaleDateString()]
    .filter((i) => i)
    .join(" - ");
};

export const toMoney = (money) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    money,
  );
