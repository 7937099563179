import { MenuIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import ProductOptionChoice from "./ProductOptionChoice";

const initializeChoicesFromOption = (option) =>
  option.choices.map((choice) => ({ ...choice, draggableId: uuidv4() }));

const formatDate = (date) => {
  let toFormat = new Date(date);
  if (!date) {
    return "";
  }
  return toFormat.toISOString().split("T")[0];
};

const ProductOption = ({ option, index }) => {
  const [choices, setChoices] = useState(initializeChoicesFromOption(option));
  const [showChoices, setShowChoices] = useState(choices.length == 0);
  const [value, setValue] = useState(option.name);
  const [status, setStatus] = useState(option.status);
  const [startAt, setStartAt] = useState(formatDate(option.startAt));
  const [endAt, setEndAt] = useState(formatDate(option.endAt));
  const [deleting, setDeleting] = useState(false);

  const addChoice = () => {
    setChoices([
      ...choices,
      { id: "", name: "", draggableId: uuidv4(), position: choices.length + 1 },
    ]);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return; // dropped outside the list
    } else if (destination.index === source.index) {
      return; // dropped into the same location
    } else {
      const moved = choices.find((_, i) => i === source.index);
      const remaining = choices.filter((_, i) => i !== source.index);
      const newChoices = [
        ...remaining.slice(0, destination.index),
        moved,
        ...remaining.slice(destination.index),
      ].map((o, i) => ({ ...o, position: i + 1 }));

      setChoices(newChoices);
    }
  };

  return (
    <Draggable draggableId={option.draggableId} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="mb-2 bg-white"
        >
          <div className="flex mb-2">
            {deleting ? (
              <div className="flex-grow border rounded p-2 border-red-400 text-red-400 bg-red-100 line-through">
                {option.id && (
                  <>
                    <input
                      type="hidden"
                      name="product[product_options_attributes][][id]"
                      value={option.id}
                    />
                    <input
                      type="hidden"
                      name="product[product_options_attributes][][_destroy]"
                      value="1"
                    />
                  </>
                )}
                {value || "-"}
              </div>
            ) : (
              <>
                <input
                  type="hidden"
                  name="product[product_options_attributes][][id]"
                  value={option.id}
                />
                <input
                  type="hidden"
                  name="product[product_options_attributes][][position]"
                  value={option.position}
                />
                <input
                  type="text"
                  className="border rounded p-2 border-gray-300 flex-grow"
                  name="product[product_options_attributes][][name]"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <input
                  type="date"
                  className="border rounded p-2 border-gray-300 flex-shrink"
                  name="product[product_options_attributes][][start_at]"
                  onChange={(e) => setStartAt(e.target.value)}
                  value={startAt}
                />
                <input
                  type="date"
                  className="border rounded p-2 border-gray-300 flex-shrink"
                  name="product[product_options_attributes][][end_at]"
                  onChange={(e) => setEndAt(e.target.value)}
                  value={endAt}
                />
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  name="product[product_options_attributes][][status]"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </>
            )}
            <div className="flex-none text-gray-300 ml-2 flex items-center">
              <div className="mr-1" {...provided.dragHandleProps}>
                <MenuIcon className="w-5 h-5" />
              </div>
              <TrashIcon
                className={`w-5 h-5 cursor-pointer ${
                  deleting ? "text-red-400" : ""
                }`}
                onClick={() => setDeleting(!deleting)}
              />
            </div>
          </div>
          <div className="pb-2">
            <div className="border-l border-gray-200 pl-4 ml-3">
              {choices.length > 0 && showChoices ? (
                <div
                  className="primary-link cursor-pointer text-xs text-center mb-2"
                  onClick={() => setShowChoices(false)}
                >
                  hide choices
                </div>
              ) : !showChoices ? (
                <div
                  className="primary-link cursor-pointer text-xs text-center"
                  onClick={() => setShowChoices(true)}
                >
                  show {choices.length} choices
                </div>
              ) : (
                ""
              )}
              <div className={showChoices ? "" : "hidden"}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="productOptions">
                    {(droppableProvided) => (
                      <div
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.draggableProps}
                      >
                        {choices.map((choice, i) => (
                          <ProductOptionChoice
                            key={choice.draggableId}
                            index={i}
                            choice={choice}
                            deletingOption={deleting}
                          />
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <PlusCircleIcon
                  className="w-5 h-5 ml-auto mr-7 text-green-500 cursor-pointer"
                  onClick={addChoice}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ProductOption;
