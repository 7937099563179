import PropTypes from "prop-types";
import React from "react";

import Invoice from "./Reports/Invoice";
import Kitchen from "./Reports/Kitchen";
import Orders from "./Reports/Orders";

const Reports = ({ id, status, ...props }) => {
  // eslint-disable-line
  const buttonStyle = {
    marginLeft: 5,
  };

  const event = { id, status };

  return (
    <>
      {status != "open" ? (
        <>
          <button onClick={Kitchen(event)}>KITCHEN.CSV</button>
          <br />
          <button onClick={Orders(event)}>ORDERS.CSV</button> <br />
        </>
      ) : null}
      <Invoice style={buttonStyle} id={id} status={status} {...props} />
    </>
  );
};

Reports.propTypes = {
  id: PropTypes.any,
  status: PropTypes.string,
};

export default Reports;
