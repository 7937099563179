import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextArea = ({ value, method, objectName }) => {
  const [text, setText] = useState(value || "");

  return (
    <>
      <input type="hidden" name={`${objectName}[${method}]`} value={text} />
      <ReactQuill theme="snow" value={text} onChange={setText} />
    </>
  );
};

export default RichTextArea;
