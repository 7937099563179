import PropTypes from "prop-types";
import React from "react";

const renderArray = (ar) => (
  <ul>
    {ar.map((a, i) => (
      <li key={i}>{a}</li>
    ))}
  </ul>
);

const renderPoint = (value) =>
  value ? (Array.isArray(value) ? renderArray(value) : value) : null;

const DataPoint = ({ children, label, value }) => (
  <dl>
    <div className="bg-gray-50 px-4 py-5 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {renderPoint(value) || children}
      </dd>
    </div>
  </dl>
);

DataPoint.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

export default DataPoint;
