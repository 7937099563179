import { View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import Blank from "./Blank";
import Due from "./Due";
import Header from "./Header";
import Orders from "./Orders";
import Subtotals from "./Subtotals";
import Summary from "./Summary";

import AdminFees from "./AdminFees";
import ProcessingFees from "./ProcessingFees";

import styles from "./styles";

const OrdersTable = ({ invoice }) => (
  <View style={styles.orders}>
    <Header />

    <View>
      <Orders orders={invoice.orderSummaries} />
      <Subtotals {...invoice.invoiceSummary} />
      <ProcessingFees {...invoice.feeSummary.transaction} />
      <AdminFees {...invoice.feeSummary.admin} />
      <Due {...invoice.feeSummary.due} />
    </View>
    <Blank count={0} />
    <Summary {...invoice.invoiceSummary} />
  </View>
);

OrdersTable.propTypes = {
  invoice: PropTypes.object,
};

export default OrdersTable;
