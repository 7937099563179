import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { toMoney } from "./helper";
import styles from "./styles";

const optionsToText = (lineItem) =>
  lineItem.options.length
    ? lineItem.options.map((option) => (
        <Text key={option.optionName}>
          {" "}
          - {option.optionName} : {option.choiceName}
        </Text>
      ))
    : null;

const notesToText = (lineItem) =>
  lineItem.notes ? <Text key="notes">NOTES: {lineItem.notes}</Text> : null;

const LineItem = ({ lineItem }) => (
  <View style={styles.lineItem}>
    <Text style={styles.parentItem}>
      {lineItem.quantity} x {lineItem.item} ({toMoney(lineItem.price)})
    </Text>
    {optionsToText(lineItem)}
    {notesToText(lineItem)}
  </View>
);

LineItem.propTypes = {
  lineItem: PropTypes.object,
};
export default LineItem;
