import {
  MenuIcon,
  PencilIcon,
  TrashIcon,
  DuplicateIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";

const Product = ({
  id,
  name,
  avatar,
  price,
  editPath,
  deletePath,
  dupPath,
  index,
  description,
  numLiveEvents,
  status,
}) => {
  const [showDescription, setShowDescription] = useState(false);

  const deleteHandler = () => {
    const youSure = window.confirm(
      "Are you sure you want to delete this product?",
    );

    if (youSure) {
      axios({
        method: "delete",
        url: deletePath,
      }).then((response) => {
        console.log(response);
        window.location.reload();
      });
    }
  };

  const dupeHandler = () => {
    axios({
      method: "get",
      url: dupPath,
    }).then((response) => {
      console.log(response);
      window.location.reload();
    });
  };

  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          className="w-full mb-2 border border-gray-100 bg-white rounded"
          {...provided.draggableProps}
        >
          <div className="w-full flex ">
            <div className="flex-none">
              {avatar ? (
                <img src={avatar} className="w-14 h-14 rounded" />
              ) : (
                <div className="w-14 h-14 bg-gray-100 rounded"></div>
              )}
            </div>
            <div className="mx-2 py-1 flex-grow">
              <div>{name}</div>
              <div className="text-sm text-gray-500">
                {price} | Status: {status} |{" "}
                <span
                  className="text-sm primary-link cursor-pointer"
                  onClick={() => setShowDescription(!showDescription)}
                >
                  {showDescription ? "Hide description" : "Show description"}
                </span>
              </div>
            </div>
            <div className="p-2 flex-none flex items-center">
              {numLiveEvents > 0 && (
                <span className="inline-flex items-center px-2.5 py-0.5 mr-4 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Live&nbsp;events:&nbsp;
                  <span className="font-bold">{numLiveEvents}</span>
                </span>
              )}
              <div className="mr-4 cursor-pointer" onClick={deleteHandler}>
                <TrashIcon className="w-5 h-5 text-gray-500" />
              </div>
              <div className="mr-4 cursor-pointer" onClick={dupeHandler}>
                <DuplicateIcon className="w-5 h-5 text-gray-500" />
              </div>
              <a href={editPath} className="mr-4">
                <PencilIcon className="w-5 h-5 text-gray-500" />
              </a>
              <div className="pointer-cursor" {...provided.dragHandleProps}>
                <MenuIcon className="w-5 h-5 text-gray-500" />
              </div>
            </div>
          </div>
          {showDescription && (
            <div
              className="ml-16 my-2 mr-2 p-3 text-sm border border-gray-100 rounded generated-markup"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default Product;
