import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { subDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
const ReportDateRangePicker = ({ onSelect }) => {
  const [ranges, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = ({ selection }) => {
    setRanges([selection]);
    onSelect(selection);
  };

  return (
    <DateRangePicker
      ranges={ranges}
      onChange={handleSelect}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      direction="horizontal"
      preventSnapRefocus={true}
      calendarFocus="backwards"
    />
  );
};

export default ReportDateRangePicker;
