import { PlusIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import React, { useState } from "react";
// import Turbo from "turbo";
import { daysOfMonth, groupByDay, showDay, showWeekDay } from "./dates";
import Legend from "./Legend";
import ProducedFilter from "./ProducedFilter";
import Proposal from "./Proposal";
import RestaurantFilter from "./RestaurantFilter";
import StatusFilter from "./StatusFilter";
import SyncedFilter from "./SyncedFilter";
import EventLocationFilter from "./EventLocationFilter";

const MAXIMUM_EVENTS_PER_DAY = 100;

const goToCreate = (date) => (e) => {
  e.preventDefault();
  return window.Turbo.visit(
    `/proposals/new?month=${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`,
  );
};

const PaddedCells = ({ maximum, current, Component }) =>
  [...Array(maximum - current).keys()].map((v) => <Component key={v} />);

const DayRow = ({
  proposals,
  bump,
  day,
  index,
  activeProposal,
  setActiveProposal,
  attribute,
  inspect,
}) => {
  const paddedCells = MAXIMUM_EVENTS_PER_DAY - proposals.length;

  const dayString = showDay(day);

  return (
    <tr
      className={index % 2 === 0 ? "bg-gray-300" : ""}
      id={dayString}
      key={dayString}
    >
      <td className="w-5">{dayString}</td>
      <td className="w-5">{showWeekDay(day)}</td>
      <td className="w-5">
        <button className="btn btn-secondary" onClick={goToCreate(day)}>
          <PlusIcon className="h-5 w-5 text-blue-500 text-center" />
        </button>
      </td>
      {(proposals || []).map((proposal, i) => (
        <Proposal
          proposal={proposal}
          key={i}
          activeProposal={activeProposal}
          setActiveProposal={setActiveProposal}
          attribute={attribute}
          inspect={inspect}
          bump={bump}
        />
      ))}
      <PaddedCells
        maximum={MAXIMUM_EVENTS_PER_DAY}
        current={proposals.length}
        Component="td"
      />
    </tr>
  );
};

const MonthlyCalendar = ({
  restaurants,
  proposals,
  month,
  inspect,
  bump,
  eventLocations,
}) => {
  const [activeProposal, setActiveProposal] = useState({});
  const [attribute, setAttribute] = useState("restaurant");
  const [producedFilter, setProducedFilter] = useState();
  const [syncedFilter, setSyncedFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [restaurantFilter, setRestaurantFilter] = useState();
  const [eventLocationFilter, setEventLocationFilter] = useState();
  const days = daysOfMonth(month);

  const groupedByDay =
    groupByDay(
      proposals
        .filter((proposal) =>
          !syncedFilter
            ? true
            : (syncedFilter == "synced" && proposal.salesforceId) ||
              (syncedFilter == "unsynced" && !proposal.salesforceId),
        )
        .filter((proposal) =>
          !producedFilter
            ? true
            : (producedFilter == "produced" && proposal.eventId) ||
              (producedFilter == "unproduced" && !proposal.eventId),
        )
        .filter((proposal) =>
          statusFilter ? proposal.status === statusFilter : true,
        )
        .filter((proposal) =>
          restaurantFilter
            ? `${restaurantFilter}` === `${proposal.restaurantId}`
            : true,
        )
        .filter((proposal) =>
          eventLocationFilter
            ? proposal.eventLocationIds.includes(Number(eventLocationFilter))
            : true,
        ),
    ) || {};

  return (
    <>
      <div className="flex items-center">
        <Legend attribute={attribute} setAttribute={setAttribute} />
        <RestaurantFilter
          restaurants={restaurants}
          restaurantFilter={restaurantFilter}
          setRestaurantFilter={setRestaurantFilter}
          inspect={inspect}
        />
        <StatusFilter
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          inspect={inspect}
        />
        <SyncedFilter
          syncedFilter={syncedFilter}
          setSyncedFilter={setSyncedFilter}
          inspect={inspect}
        />
        <ProducedFilter
          producedFilter={producedFilter}
          setProducedFilter={setProducedFilter}
          inspect={inspect}
        />
        <EventLocationFilter
          eventLocations={eventLocations}
          eventLocationFilter={eventLocationFilter}
          setEventLocationFilter={setEventLocationFilter}
          inspect={inspect}
        />
      </div>
      <table className="backoffice-table">
        <thead>
          <tr>
            <th className="w-5">Day</th>
            <th colSpan="2" className="w-10">
              WeekDay
            </th>
            <th colSpan={MAXIMUM_EVENTS_PER_DAY - proposals.length}>
              Event Proposals
            </th>
          </tr>
        </thead>
        <tbody>
          {days.map((day, i) => (
            <DayRow
              bump={bump}
              day={day}
              proposals={groupedByDay[showDay(day)] || []}
              key={i}
              index={i}
              activeProposal={activeProposal}
              setActiveProposal={setActiveProposal}
              attribute={attribute}
              inspect={inspect}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

MonthlyCalendar.propTypes = {
  month: PropTypes.instanceOf(Date),
};
export default MonthlyCalendar;
