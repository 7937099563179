import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { downloadCSV } from "../sendCSV";

const humanizeDate = (object, field) => {
  const date = object[field];
  if (!date) return "";

  return new Date(Date.parse(date)).toLocaleString();
};

export const splitDate = (object, field) => {
  const date = object[field];
  if (!date) return [];

  const dt = new Date(Date.parse(date));
  const pad = (val) => (`${val}`.length === 1 ? `0${val}` : `${val}`);

  const get = (obj) => (field) => obj[`get${field}`]();

  return [
    `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`,

    ["Hours", "Minutes", "Seconds"].map(get(dt)).map(pad).join(":"),
  ];
};

const projectMembershipsExporter = (projectMemberships) => {
  const projectMembershipsForExport = projectMemberships.map(
    (projectMembership) => {
      const {
        completed_at, // eslint-disable-line
        ...projectMembershipForExport
      } = projectMembership; // omit backlinks and author

      projectMembershipForExport.due_at = humanizeDate(
        projectMembership,
        "due_at",
      );
      projectMembershipForExport.created_at = humanizeDate(
        projectMembership,
        "created_at",
      );
      projectMembershipForExport.updated_at = humanizeDate(
        projectMembership,
        "updated_at",
      );
      projectMembershipForExport.completed_at = humanizeDate(
        projectMembership,
        "completed_at",
      );

      const created = splitDate(projectMembership, "created_at");

      const updated = splitDate(projectMembership, "updated_at");

      const completed = splitDate(projectMembership, "completed");

      const redeemed = splitDate(projectMembership, "redeemed_at");

      projectMembershipForExport.created_at_date = created[0];
      projectMembershipForExport.created_at_time = created[1];
      projectMembershipForExport.updated_at_date = updated[0];
      projectMembershipForExport.updated_at_time = updated[1];
      projectMembershipForExport.completed_at_date = completed[0];
      projectMembershipForExport.completed_at_time = completed[1];

      projectMembershipForExport.redeemed_at_date = redeemed[0];
      projectMembershipForExport.redeemed_at_time = redeemed[1];

      return projectMembershipForExport;
    },
  );

  const csv = convertToCSV({
    data: projectMembershipsForExport, //,
    // fields: ['id', 'name', 'created_at', 'updated_at', 'join_by', 'description', 'tasks_count', 'postal_code', 'member_ids', 'professional_ids', 'status'] //,
    // fields: ['id', 'title', 'author_name', 'body'] // order fields in the export
  });
  downloadCSV(csv, "project_memberships");
};

export default projectMembershipsExporter;
