import axios from "axios";

const MAX_RESTAURANTS = 300;
const MAX_PROPOSALS = 1000;

export const getProposalsForMonth = (date) =>
  axios.get(`/proposals.json`, { params: { month: date, per: MAX_PROPOSALS } });

export const getRestaurants = (date) =>
  axios.get(`/restaurants.json`, { params: { per: MAX_RESTAURANTS } });

export const getRestaurant = (id) => axios.get(`/restaurants/${id}.json`);

export const getEventLocations = () => axios.get(`/event_locations.json`);
export const getEventLocation = (id) =>
  axios.get(`/event_locations/${id}.json`);

export const exportToSalesforce = (id) =>
  axios.put(`/proposals/${id}/export.json`);

export const destroyProposal = (id) => axios.delete(`/proposals/${id}.json`);
