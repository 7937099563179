import React from "react";
import FlashMessage from "./FlashMessage";

export default function FlashWrapper({ flashes }) {
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {flashes.map((flash, i) => (
            <FlashMessage
              key={i}
              title={flash.title}
              message={flash.message}
              status={flash.status}
            />
          ))}
        </div>
      </div>
    </>
  );
}
