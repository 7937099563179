import { MenuIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";

const ProductOptionChoice = ({ choice, deletingOption, index }) => {
  const [deleting, setDeleting] = useState(false);
  const [value, setValue] = useState(choice.name);
  const [status, setStatus] = useState(choice.status);
  const formatDate = (date) => {
    let toFormat = new Date(date);
    if (!date) {
      return "";
    }
    return toFormat.toISOString().split("T")[0];
  };
  const [startAt, setStartAt] = useState(formatDate(choice.startAt));
  const [endAt, setEndAt] = useState(formatDate(choice.endAt));

  return (
    <Draggable draggableId={choice.draggableId} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="flex mb-2"
        >
          {deleting || deletingOption ? (
            <div className="flex-grow border rounded text-sm p-2 border-red-400 text-red-400 bg-red-100 line-through">
              {choice.id && (
                <>
                  <input
                    type="hidden"
                    name="product[product_options_attributes][][product_option_choices_attributes][][id]"
                    value={choice.id}
                  />
                  <input
                    type="hidden"
                    name="product[product_options_attributes][][product_option_choices_attributes][][_destroy]"
                    value="1"
                  />
                </>
              )}
              {value || "-"}
            </div>
          ) : (
            <>
              <input
                type="hidden"
                name="product[product_options_attributes][][product_option_choices_attributes][][id]"
                value={choice.id}
              />
              <input
                type="hidden"
                name="product[product_options_attributes][][product_option_choices_attributes][][position]"
                value={choice.position}
              />
              <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                name="product[product_options_attributes][][product_option_choices_attributes][][name]"
                className="flex-grow border rounded text-sm p-2 border-gray-300"
              />
              <input
                type="date"
                className="border rounded p-2 border-gray-300 flex-shrink"
                name="product[product_options_attributes][][product_option_choices_attributes][][start_at]"
                onChange={(e) => setStartAt(e.target.value)}
                value={startAt}
              />
              <input
                type="date"
                className="border rounded p-2 border-gray-300 flex-shrink"
                name="product[product_options_attributes][][product_option_choices_attributes][][end_at]"
                onChange={(e) => setEndAt(e.target.value)}
                value={endAt}
              />
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                name="product[product_options_attributes][][product_option_choices_attributes][][status]"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </>
          )}
          <div className="flex-none text-gray-300 ml-1 flex items-center">
            <div className="mx-1" {...provided.dragHandleProps}>
              <MenuIcon className="w-5 h-5" />
            </div>
            <TrashIcon
              className={`w-5 h-5 cursor-pointer ${
                deleting || deletingOption ? "text-red-400" : ""
              }`}
              onClick={() => setDeleting(!deleting)}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ProductOptionChoice;
