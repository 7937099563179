import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  MailIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import useQuery from "hooks/useQuery";
import React from "react";

const Driver = ({ driver, isLoading, error, eventId }) => {
  const sendInstructionsQuery = useQuery({
    queryFn: () =>
      axios({
        method: "post",
        url: "/driver_delivery_instructions",
        data: {
          event_id: eventId,
          email: driver.email,
          name: driver.name,
          phone: driver.phone,
        },
      }),
    manualExecute: true,
  });

  const handleOnClick = () => {
    if (sendInstructionsQuery.isIdle) sendInstructionsQuery.execute();
  };

  return (
    <>
      <div className="font-bold">{driver.name}</div>
      <div className="text-xs">
        {driver.email || "(no email)"} - {driver.phone || "(no phone number)"}
      </div>
      <div className="pt-4">
        {sendInstructionsQuery.isIdle ? (
          <button className="btn-secondary-small -mt-1" onClick={handleOnClick}>
            Send Delivery Instructions
          </button>
        ) : !sendInstructionsQuery.isFinished ? (
          <div className="flex flex-row items-center">
            <MailIcon className="w-4 h-4 mr-1 animate-spin" />
            Sending
          </div>
        ) : sendInstructionsQuery.error ? (
          <div className="flex flex-row items-center text-red-600">
            <ExclamationCircleIcon className="w-4 h-4 mr-1" /> Error:{" "}
            {sendInstructionsQuery.error}
          </div>
        ) : (
          <div className="flex flex-row items-center text-green-600">
            <CheckCircleIcon className="w-4 h-4 mr-1" /> Delivery instructions
            sent!
          </div>
        )}
      </div>
    </>
  );
};

export default Driver;
