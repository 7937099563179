import React from "react";

const timeFormatter = (string) =>
  new Date(string).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  });

const EventCommLogItem = ({ label, log }) => {
  const count = typeof log?.count === "number" ? `: ${log.count} sent ` : " ";

  return (
    <li className="text-xs">
      {label}:{" "}
      {!log ? (
        <span className="text-gray-500">Not yet sent</span>
      ) : log.success ? (
        <span className="text-green-500">
          Succeeded{count}({timeFormatter(log.time)})
        </span>
      ) : (
        <span className="text-red-500">
          Failed{count}({timeFormatter(log.time)})
        </span>
      )}
    </li>
  );
};

export default EventCommLogItem;
