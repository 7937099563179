import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#FFFFFF",
    fontSize: 10,
    fontWeight: "light",
  },
  section: {
    marginBottom: 10,
    // padding: 10,
    // flexGrow: 1
  },
  chit: {
    width: 275,
    height: "47%",
    padding: 10,
  },

  chit0: {
    marginLeft: 20,
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#000000",
  },

  chit1: {
    marginTop: 20,
    borderTopWidth: 1,
    borderTopColor: "#000000",
    borderRightWidth: 1,
    borderRightColor: "#000000",
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
  },

  chit2: {
    marginLeft: 20,
    marginBottom: 20,
    borderLeftWidth: 1,
    borderLeftColor: "#000000",
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
    borderRightWidth: 1,
    borderRightColor: "#000000",
  },

  chit3: {
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
    borderRightWidth: 1,
    borderRightColor: "#000000",
  },

  userName: {
    fontWeight: "bold",
    fontSize: 16,
    // fontSize: "20px",
    // color: "#000000"
  },

  projectName: {},

  details: {
    flex: 1,
    flexDirection: "row",
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: "#000000",
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
  },

  pickup: {},

  notes: {},

  lineItems: {},

  lineItem: {
    marginTop: 10,
  },

  title: {
    color: "#888888",
  },
});

export default styles;
