import { StyleSheet } from "@react-pdf/renderer";
import { dsGreen } from "../styles";
const borderColor = dsGreen;

const numberCell = {
  textAlign: "right",
  paddingRight: 8,
};

export default StyleSheet.create({
  // orders component
  orders: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: borderColor,
  },

  // blank component
  blankRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    color: "white",
  },
  blankDescription: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  blankQuantity: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  blankRate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  blankAmount: {
    width: "15%",
  },

  // header component
  headerContainer: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  headerId: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerSubtotal: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerRefund: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerTax: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerTip: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerServiceFee: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerTotal: {
    width: "10%",
  },

  // row component
  rowRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  rowId: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  rowBold: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontWeight: "bold",
  },

  rowSubtotal: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    ...numberCell,
  },
  rowRefund: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    ...numberCell,
  },
  rowTax: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    ...numberCell,
  },
  rowTip: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    ...numberCell,
  },
  rowServiceFee: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    ...numberCell,
  },

  rowTotal: {
    width: "10%",
    ...numberCell,
  },

  // footer component
  footerRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  footerLabel: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  footerValue: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  footerTotal: {
    fontWeight: "bold",
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});
