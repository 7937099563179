import { Image, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

const logo = require("./logo.png"); // eslint-disable-line

const Header = ({ title }) => (
  <>
    <Image style={styles.logo} src={logo} />
    <View style={styles.header}>
      <Text style={styles.headerText}>{title}</Text>
    </View>
  </>
);

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
