import { Document, Page, PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import Chit, { lineItemsRows, MAX_ROWS, totalRows } from "./Chit";
import styles from "./styles";

const inGroups = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );

// Needs to be aware of options
const inLineItemGroups = (lis) => {
  const groups = [];

  let current = [];

  const sizeOfCurrent = () => lineItemsRows(current);

  lis.forEach((li) => {
    if (sizeOfCurrent() + lineItemsRows([li]) >= MAX_ROWS) {
      groups.push(current);
      current = [li];
    } else {
      current.push(li);
    }
  });

  if (current.length > 0) {
    groups.push(current);
  }
  return groups;
};

const paginateChits = (chits) =>
  chits.reduce((ac, chit) => {
    const overflow = totalRows(chit) >= MAX_ROWS;

    if (overflow) {
      const chunks = inLineItemGroups(chit.lineItems, MAX_ROWS);

      const splitChits = chunks.map((lis, index) => ({
        ...chit,
        index: index + 1,
        pages: chunks.length,
        lineItems: lis,
      }));

      return ac.concat(splitChits);
    } else {
      return ac.concat([chit]);
    }
  }, []);

const filterChit = (chit) => chit.lineItems.length > 0;

// Create Document Component
export const ChitList = ({ chits, perPage }) => {
  const paginatedChits = paginateChits(Object.values(chits).filter(filterChit));
  const pages = inGroups(paginatedChits, perPage || 4);

  return (
    <Document>
      {pages.map((page, i) => (
        <Page size="A4" style={styles.page} key={i}>
          {page.map((chit, j) => (
            <Chit
              key={j}
              chit={chit}
              i={j}
              full={perPage == 1}
              preClose={chit.preClose}
            />
          ))}
        </Page>
      ))}
    </Document>
  );
};

const ChitListButton = ({ chits, title, style, perPage = 4 }) =>
  !chits ? null : (
    <PDFDownloadLink
      document={<ChitList chits={chits} perPage={perPage} />}
      fileName="chitlist.pdf"
      style={style}
      className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-47 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
    >
      {({ loading }) =>
        loading ? "Loading document..." : title || "CHITS.PDF"
      }
    </PDFDownloadLink>
  );

export default ChitListButton;
