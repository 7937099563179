import React, { useState } from "react";

import {
  DiscreteColorLegend,
  Hint,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  XYPlot,
  LineSeries,
} from "react-vis";

const RestaurantProfitability = ({ eventRevenues }) => {
  const [hint1, setHint1] = useState(false);
  const [hint2, setHint2] = useState(false);
  const resetHints = () => {
    setHint1(false);
    setHint2(false);
  };

  const restaurantData = eventRevenues.map((er) => ({
    x: new Date(er.runAt),
    y: parseInt(er.restaurantRevenue),
  }));

  const dwellsocialData = eventRevenues.map((er) => ({
    x: new Date(er.runAt),
    y: parseInt(er.dwellsocialRevenue),
  }));

  return (
    <>
      <DiscreteColorLegend
        height={90}
        width={300}
        items={[
          { title: "DwellSocial", color: "#86BCDCFF" },
          { title: "Restaurant", color: "#44848EFF" },
        ]}
      />
      <XYPlot xType="time" height={400} width={1200} onMouseLeave={resetHints}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis title="Event Date" />
        <YAxis title="Revenue" />
        <LineSeries
          data={restaurantData}
          onNearestXY={(e, { index }) => setHint1(restaurantData[index])}
          onSeriesMouseOut={() => setHint1(false)}
        />
        {hint1 ? <Hint className="first-hint" value={hint1} /> : null}

        <LineSeries
          data={dwellsocialData}
          onSeriesMouseOut={() => setHint2(false)}
          onNearestXY={(e, { index }) => setHint2(dwellsocialData[index])}
        />
        {hint2 ? <Hint className="second-hint" value={hint2} /> : null}
      </XYPlot>
    </>
  );
};

export default RestaurantProfitability;
