import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import LineItem from "./LineItem";
import styles from "./styles";

export const MAX_ROWS = 16;

const notesLineCount = (notes) =>
  notes ? (notes.match(/\n/g) || "").length + 1 : 0;

export const lineItemsRows = (lis) =>
  lis.length +
  lis
    .map((li) => li.options.length + notesLineCount(li.notes))
    .reduce((sum, i) => sum + i, 0);

export const totalRows = (chit) => lineItemsRows(chit.lineItems);

const Section = ({ style, title, children }) => (
  <View style={[style || {}, styles.section]}>
    {title ? <Text style={styles.title}>{title}</Text> : null}
    {children}
  </View>
);

Section.propTypes = {
  style: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.any,
};

const ChitMeta = ({ chit }) => (
  <>
    <View style={[styles.deetsPickup]}>
      <Text>{chit.d2d ? "DELIVERY" : "PICKUP"} FOR 1</Text>
      <Text>{[chit.pickupAt]}</Text>
    </View>
  </>
);

ChitMeta.propTypes = {
  chit: PropTypes.object,
};

const Chit = ({ chit, full, i, preClose }) => (
  <View style={[styles[full ? "fullChit" : "chit"], styles["chit" + i]]}>
    {preClose ? (
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          opacity: 0.2,
          zIndex: 99999,
          color: "#AAAAAA",
          // transform: 'rotate(-45deg)'
        }}
      >
        <Text style={{ fontSize: 60 }}>tentative</Text>
        <Text style={{ fontSize: 60 }}>tentative</Text>
        <Text style={{ fontSize: 60 }}>tentative</Text>
        <Text style={{ fontSize: 60 }}>tentative</Text>
        <Text style={{ fontSize: 60 }}>tentative</Text>
        <Text style={{ fontSize: 60 }}>tentative</Text>
      </View>
    ) : null}

    <Section>
      <Text style={styles.userName}>
        {chit.customer}
        {chit.index ? ` - ${chit.index} of ${chit.pages}` : ""}
      </Text>
      <Text style={styles.userPhone}>{chit.phone}</Text>
      {chit.d2d ? <Text style={styles.userAddress}>{chit.address}</Text> : null}
    </Section>

    <Section>
      <View style={styles.deets}>
        <View style={[styles.deetsChild]}>
          <ChitMeta chit={chit}></ChitMeta>
        </View>
      </View>
    </Section>

    <Section style={styles.pickup} title="EXPERIENCE">
      <Text>
        {chit.experience} with DwellSocial, {chit.pickupAt}
      </Text>
    </Section>

    <Section style={styles.lineItems} title="LINE ITEMS">
      {chit.lineItems.map((li, i) => (
        <LineItem key={i} lineItem={li} />
      ))}
    </Section>

    {chit.orderNotes ? (
      <Section style={styles.notes} title="NOTES">
        <Text key={i}>{chit.orderNotes}</Text>
      </Section>
    ) : null}
  </View>
);

Chit.propTypes = {
  chit: PropTypes.object,
  full: PropTypes.bool,
  i: PropTypes.number,
  preClose: PropTypes.bool,
};

export default Chit;
