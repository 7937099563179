import { splitDate } from "./membershipsExporter";

describe("membershipExporter", () => {
  it("allows splitting a date", () => {
    expect(splitDate({}, "missing")).toEqual([]);

    const date = "2018-04-30T00:00:00.000Z";
    const splits = splitDate({ date }, "date");

    expect(splits[0]).toMatch(/\d+\/\d+\/\d{4}/);
    expect(splits[1]).toMatch(/\d{2}:\d{2}:\d{2}/);
  });
});
