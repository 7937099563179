import { useEffect, useState } from "react";

const useQuery = ({ queryFn, initialData = {}, manualExecute = false }) => {
  const [isFinished, setIsFinished] = useState(false);
  const [isIdle, setIsIdle] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(initialData);

  const execute = () => {
    setIsIdle(false);

    queryFn()
      .then((r) => setData(r.data))
      .catch((e) =>
        setError(e?.response?.data?.error || "Something went wrong"),
      )
      .finally(() => setIsFinished(true));
  };

  useEffect(() => {
    if (!manualExecute) execute();
  }, []);

  return { isFinished, isIdle, error, data, execute };
};

export default useQuery;
