import { ClipboardCopyIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

export default function CampaignLinks({
  links,
  utmParams,
  handleCreateMissing,
}) {
  const [showAll, setShowAll] = useState(false);
  const [clipColor, setClipColor] = useState("text-gray-500");
  const [hideCreateMissing, setHideCreateMissing] = useState(false);

  const highlightedUTM = utmParams.replace(
    /=([^&]+)/g,
    "=<span class='font-bold underline'>$1</span>",
  );

  const withProtocol = (link) =>
    link.match(/^http/) ? link : "https://" + link;
  const withoutProtocol = (link) => link.replace(/^https?:\/\//, "");
  const linksToShow = showAll || links.length == 4 ? links : links.slice(0, 3);
  const showMore = !showAll && links.length > linksToShow.length;

  const copyLinks = () => {
    let text = links
      .map((l) => `${l.label}: ${l.shortUrl ? withProtocol(l.shortUrl) : ""}`)
      .join("\n");

    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      console.log("clipboard unavailable");
      console.log(text);
    }

    setClipColor("text-green-500");
    setTimeout(() => {
      setClipColor("text-gray-500");
    }, 500);
  };

  const linkElement = (link) => {
    if (link.shortUrl) {
      return (
        <a
          href={withProtocol(link.shortUrl)}
          className="text-indigo-600 hover:text-indigo-900"
        >
          {withoutProtocol(link.shortUrl)}
        </a>
      );
    } else if (link.status == "pending") {
      return <span className="text-gray-500">(generating...)</span>;
    } else if (link.status == "failed") {
      return <span className="text-red-500">(error)</span>;
    } else {
      return <span className="text-gray-500">(none)</span>;
    }
  };

  const missingLinks = links.filter((l) => !l.id);

  const handleClickCreateMissing = () => {
    setHideCreateMissing(true);

    handleCreateMissing({
      utmParams,
      eventIDs: missingLinks.map((l) => l.eventId),
    });
  };

  console.log(missingLinks);

  return (
    <div>
      <dt className="group flex items-center">
        Campaign links
        <span className="cursor-pointer" onClick={copyLinks}>
          <ClipboardCopyIcon className={`w-4 h-4 ml-1 ${clipColor}`} />
        </span>
      </dt>
      <dd>
        <div
          className="text-gray-500 text-xs mb-2 break-words"
          dangerouslySetInnerHTML={{ __html: highlightedUTM }}
        />
        <div className="truncate">
          <table className="text-xs">
            <tbody>
              {linksToShow.map((link, i) => (
                <tr key={i}>
                  <td className="pr-1">{link.label}:</td>
                  <td>{linkElement(link)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {showMore && (
            <div className="text-center mt-2 text-xs">
              <span
                className="primary-link cursor-pointer"
                onClick={() => setShowAll(true)}
              >
                show all ({links.length - linksToShow.length} more)
              </span>
            </div>
          )}
          {missingLinks.length > 0 && !hideCreateMissing && (
            <div className="text-center mt-2 text-xs">
              <span
                className="primary-link cursor-pointer"
                onClick={handleClickCreateMissing}
              >
                create missing
              </span>
            </div>
          )}
        </div>
      </dd>
    </div>
  );
}
