import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { downloadCSV } from "../sendCSV";

const usersExporter = (users) => {
  const usersForExport = users.map((user) => {
    const { home, ...userForExport } = user; // omit backlinks and author

    userForExport.created_at = (user.created_at || "").split("T")[0];
    userForExport.updated_at = (user.updated_at || "").split("T")[0];
    userForExport.last_sign_in_at = (user.last_sign_in_at || "").split("T")[0];
    userForExport.confirmed_at = (user.confirmed_at || "").split("T")[0];

    userForExport.street = home && home.street;
    userForExport.city = home && home.city;
    userForExport.state = home ? home.state : "";
    userForExport.postal_code = home ? home.postal_code : "";

    return userForExport;
  });

  const csv = convertToCSV({
    data: usersForExport, //,
    // fields: ['id', 'name', 'created_at', 'updated_at', 'join_by', 'description', 'tasks_count', 'postal_code', 'member_ids', 'professional_ids', 'status'] //,
    // fields: ['id', 'title', 'author_name', 'body'] // order fields in the export
  });
  downloadCSV(csv, "users");
};

export default usersExporter;
