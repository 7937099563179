import axios from "axios";
import useInterval from "hooks/useInterval";
import React, { useState } from "react";
import CampaignLinkCreator from "./CampaignLinkCreator";
import CampaignLinks from "./CampaignLinks";

export default function CampaignLinkWrapper({ eventIDs, campaignLinkGroups }) {
  const [linkGroups, setLinkGroups] = useState(campaignLinkGroups);
  const [postError, setPostError] = useState(null);
  const [loadingParams, setLoadingParams] = useState(null);

  const handleCreateNew = (utmParams) => {
    setPostError(null);
    setLoadingParams(utmParams);

    axios({
      method: "post",
      url: "/campaign_links.json",
      data: {
        campaign_links: {
          project_ids: eventIDs,
          params: utmParams,
        },
      },
    })
      .then((resp) => {
        setLoadingParams(null);
        setLinkGroups([
          ...linkGroups,
          {
            utmParams: utmParams,
            links: resp.data,
          },
        ]);
      })
      .catch((error) => {
        let message = error.response.data.error;
        setLoadingParams(null);
        setPostError(message);
      });
  };

  const handleCreateMissing = ({ utmParams, eventIDs }) => {
    axios({
      method: "post",
      url: "/campaign_links.json",
      data: {
        campaign_links: {
          project_ids: eventIDs,
          params: utmParams,
        },
      },
    }).then((resp) => {
      setLinkGroups(
        linkGroups.map((group) => ({
          ...group,
          links: group.links.map(
            (link) =>
              resp.data.find(
                (l) =>
                  l.eventId == link.eventId && utmParams == group.utmParams,
              ) || link,
          ),
        })),
      );
    });
  };

  const pendingLinks = linkGroups
    .map((group) => group.links.filter((l) => l.status === "pending"))
    .flat();

  const linkCheckDelay = pendingLinks.length == 0 ? null : 1000;

  const checkPendingLinks = () => {
    let ids = pendingLinks.map((l) => l.id);

    if (ids.length > 0) {
      axios({
        url: "/campaign_links.json",
        params: { id: ids },
      }).then((resp) =>
        setLinkGroups(
          linkGroups.map((group) => ({
            ...group,
            links: group.links.map(
              (link) => resp.data.find((l) => l.id == link.id) || link,
            ),
          })),
        ),
      );
    }
  };

  useInterval(checkPendingLinks, linkCheckDelay);

  return (
    <div className="grid grid-cols-3 mt-6 gap-4">
      {linkGroups.map((group, i) => (
        <CampaignLinks
          utmParams={group.utmParams}
          links={group.links}
          handleCreateMissing={handleCreateMissing}
          key={i}
        />
      ))}
      {loadingParams && <CampaignLinks utmParams={loadingParams} links={[]} />}
      <CampaignLinkCreator
        eventIDs={eventIDs}
        handleSubmit={handleCreateNew}
        error={postError}
      />
    </div>
  );
}
