import exporter from "../exporter/index";
import { fields, labels, query } from "./data";

// START AGGREGATION OF OPTIONS
const choice = ([name, count]) => `${count}x ${name}`;

const choices = (list) => Object.entries(list).map(choice).join("\n");

const option = ([name, counts]) => `${name}:\n` + choices(counts);

const matchingChoiceCounts = (opts) => {
  const res = Object.entries(
    Object.entries(opts)
      .map((pr) => pr[1]) // key (e.g. Donut #1/2) useless for this
      .reduce(
        (rows, chs) => ({
          ...rows,
          ...Object.entries(chs).reduce(
            (ch, kv) => ({
              ...ch,
              [kv[0]]: (rows[kv[0]] || 0) + kv[1],
            }),
            {},
          ),
        }),
        {},
      ),
  );

  return res;
};

const options = (opts, pkg) =>
  pkg // eslint-disable-line
    ? matchingChoiceCounts(opts).map(choice).join("\n\n")
    : Object.entries(opts).map(option).join("\n\n");
// END AGGREGATION OF OPTIONS

const Kitchen = exporter({
  filename: "kitchen",
  source: "/api/admin/product_assignments",
  fields,
  labels,
  query,
  transformer: (item) =>
    item?.total > 0
      ? {
          ...item,
          opts: options(item.options, item.package),
          all_notes: item.all_notes.map((note) => `- ${note}`).join("\n"),
        }
      : null,
});

export default Kitchen;
