import PropTypes from "prop-types";
import React from "react";
import Search from "./search";

const formatDate = (date) => (date && date.length ? date.split("T")[0] : date);

const renderer = (option) =>
  `${option.name} (${option.id}) - ${option.aasm_state} - ${formatDate(
    option.run_at,
  )}`;

const queryTransform = (term) => `${term} AND aasm_state:(open OR closed)`;
const ProjectSearch = ({ onChange }) => (
  <Search
    onChange={onChange}
    renderer={renderer}
    source="api/admin/projects"
    filters={{ live: true }}
    fields={["id", "name", "join_by", "aasm_state"]}
    queryTransform={queryTransform}
    sort={["id", "DESC"]}
  />
);

ProjectSearch.propTypes = {
  onChange: PropTypes.func,
};

export default ProjectSearch;
