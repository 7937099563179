import { List, ListItem, ListItemText } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Section from "../../backoffice/Section";
import timeAgo from "../../helpers/ago";
import { getTickets } from "../../helpers/api";

const ticketUrl = (apiUrl) =>
  apiUrl.replace("api/v2/", "").replace(".json", "");

const Ticket = ({ id, created_at, status, url, subject }) => (
  <ListItem button key={id}>
    <a rel="noopener noreferrer" target="_blank" href={ticketUrl(url)}>
      <ListItemText
        primary={`${subject} - ${status}`}
        secondary={timeAgo(created_at)}
      />
    </a>
  </ListItem>
);

Ticket.propTypes = {
  id: PropTypes.any,
  created_at: PropTypes.any,
  status: PropTypes.string,
  url: PropTypes.string,
  subject: PropTypes.string,
};

const Tickets = ({ id }) => {
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    if (id) {
      getTickets(id)
        .then(({ data }) => setTickets(data))
        .catch((error) => setError(error));
    }
  }, [id]); // eslint-disable-line

  return (
    <Section
      title="Tickets"
      subtitle="Zendesk"
      icon={BugReportIcon}
      loaded={true}
    >
      <List>
        {(tickets || []).map((record) => (
          <Ticket key={record.id} {...record} />
        ))}
      </List>
      {error ? <Error /> : ""}
    </Section>
  );
};

Tickets.propTypes = {
  id: PropTypes.any,
};

export default Tickets;
