export const fields = [
  "customer",
  "item",
  "options_message",
  "quantity",
  "order_notes",
  "notes",
  "price",
  "cost",
  "pickup_time",
];

export const labels = [
  "Customer",
  "Item",
  "Options",
  "Quantity",
  "Order Notes",
  "Notes",
  "Price",
  "Cost",
  "PickupTime",
];

// CSV EXPORT
export const query = (event) => ({
  filter: JSON.stringify({
    fields,
    project_id: Array.isArray(event) ? event : event.id,
    ordered: true,
  }),
  page: 1,
  per: 10000,
  sort: JSON.stringify(["created_at", "ASC"]),
});
