import axios from "axios";
import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Product from "./Product";

const ProductList = ({ products, menuCategoryId, restaurantId }) => {
  const [productList, setProductList] = useState(products);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return; // dropped outside the list
    } else if (destination.index === source.index) {
      return; // dropped into the same location
    } else {
      const moved = productList.find((_, i) => i === source.index);
      const remaining = productList.filter((_, i) => i !== source.index);
      const newProductList = [
        ...remaining.slice(0, destination.index),
        moved,
        ...remaining.slice(destination.index),
      ].map((p, i) => ({ ...p, position: i + 1 }));

      setProductList(newProductList);

      axios({
        method: "put",
        url: `/restaurants/${restaurantId}.json`,
        data: {
          restaurant: {
            products_attributes: newProductList.map((product) => ({
              id: product.id,
              position: product.position,
            })),
          },
        },
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={menuCategoryId.toString()}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {productList.map((product, i) => (
              <Product key={product.id} index={i} {...product} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductList;
