import {
  Card,
  Container,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import CardIcon from "../../backoffice/CardIcon";

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
  },
  card: {
    padding: "16px 0",
    overflow: "inherit",
    textAlign: "right",
  },
  section: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  title: {
    padding: "0 16px",
  },
  value: {
    padding: "0 16px",
    minHeight: 48,
  },
});

const Section = ({ children, icon, subtitle, title, loaded }) => {
  const classes = useStyles();

  return (
    <Container className={classes.section}>
      <CardIcon Icon={icon} bgColor="#4caf50" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant="h5" component="h2" className={classes.value}>
            {subtitle}
          </Typography>
        ) : (
          ""
        )}
        <Divider />
        {loaded ? children : <p>Loading</p>}
      </Card>
    </Container>
  );
};

Section.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  children: PropTypes.any,
  loaded: PropTypes.bool,
};

export default Section;
