import exporter from "../exporter/index";
import { fields, labels, query } from "./data";

const Orders = exporter({
  filename: "orders",
  source: "/api/admin/line_items",
  fields,
  labels,
  query,
  transformer: (item) =>
    item.sku == "fee" || item.item == "fee"
      ? { customer: item.customer }
      : item,
});

export default Orders;
