import axios from "axios";
import useQuery from "hooks/useQuery";
import React from "react";
import DriverWrapper from "./DriverWrapper";
import EventCommLogItem from "./EventCommLogItem";

const EventList = ({ events }) => {
  const driversQuery = useQuery({
    queryFn: () =>
      axios({
        method: "get",
        url: "/drivers",
        params: {
          event_salesforce_ids: events.map((e) => e.salesforceId),
        },
      }),
    initialData: [],
  });

  const driverForEvent = (event) =>
    driversQuery.data.find((d) =>
      d.eventSalesforceIds.includes(event.salesforceId),
    );

  if (events.length == 0) {
    return (
      <div className="well">
        <div className="well-body">
          <div>No events</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {driversQuery.error && (
        <div className="rounded text-red-900 text-sm bg-red-200 border border-red-300 mb-4 p-4 w-full">
          Could not load driver data: {driversQuery.error}
        </div>
      )}
      {events.map((event) => (
        <div className="well" key={event.id}>
          <div className="well-header">
            <a href={event.retaurantPath}>
              <span className="font-bold hover:underline">
                {event.restaurantName}
              </span>
            </a>{" "}
            in {event.city}
          </div>
          <div className="well-body">
            <div className="grid grid-cols-2 gap-4">
              <DriverWrapper
                isLoading={!driversQuery.isFinished}
                error={driversQuery.error}
                driver={driverForEvent(event)}
                eventId={event.id}
              />
              <div>
                <dt>Automated Comms</dt>
                <dd>
                  <ul>
                    <EventCommLogItem
                      label="Order reminder emails"
                      log={event.commLogs.orderReminderEmails}
                    />
                    <EventCommLogItem
                      label="Share texts"
                      log={event.commLogs.shareTexts}
                    />
                    <EventCommLogItem
                      label="Pickup instructions"
                      log={event.commLogs.pickupInstructions}
                    />
                    <EventCommLogItem
                      label="Driver delivery instructions"
                      log={event.commLogs.driverDeliveryInstructions}
                    />
                    <EventCommLogItem
                      label="Post order surveys"
                      log={event.commLogs.postOrderSurveys}
                    />
                  </ul>
                </dd>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default EventList;
