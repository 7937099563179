// CSV EXPORT
export const query = (project) => ({
  filter: JSON.stringify({
    fields: ["name", "package", "options", "total", "all_notes"],
    project_id: Array.isArray(project) ? project : project.id,
  }),
  page: 1,
  per: 10000,
  sort: JSON.stringify(["name", "ASC"]),
});

export const fields = ["name", "opts", "all_notes", "total"];

export const labels = ["Name", "Options", "All Notes", "Total"];
