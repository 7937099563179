import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getUser } from "../../helpers/api";
import Emails from "./emails";
import Orders from "./orders";
import Profile from "./profile";
import Texts from "./texts";
import Tickets from "./tickets";

const User = ({ id }) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (id) {
      getUser(id)
        .then(({ data }) => setUser(data))
        .catch((error) => setError(error));
    }
  }, [id]); // eslint-disable-line

  if (!id) return "";

  return (
    <div style={{ marginTop: "4rem" }}>
      {<Profile id={id} user={user} />}
      {<Orders id={id} user={user} />}
      {<Texts id={id} user={user} />}
      {<Tickets id={id} user={user} />}
      {<Emails id={id} user={user} />}
      {error ? <p>{error}</p> : ""}
    </div>
  );
};

User.propTypes = {
  id: PropTypes.any,
};

export default User;
