import { getInvoice } from "../../../helpers/api";

const data = ({ id }) =>
  new Promise((resolve, reject) => {
    if (!id) return reject(new Error("no id for order data"));

    return getInvoice(id)
      .then(({ data }) => {
        return resolve(data);
      })
      .catch((error) => console.log("Invoice Loading Failed", error));
  });

export default data;
