import {
  EyeIcon,
  PencilIcon,
  SwitchVerticalIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import React, { useState } from "react";
// import Turbo from "turbo";
import { destroyProposal, exportToSalesforce } from "./data";
import { showDay, showTime, showWeekDay } from "./dates";
import { COLORS } from "./Legend";

const goToEdit = (id) => (e) => {
  e.preventDefault();

  return window.Turbo.visit(`/proposals/${id}/edit`);
};

const exportToSalesforceHandler =
  (id, onSuccess, clicked, setClicked) => (e) => {
    if (clicked) {
      console.log("Double clicked export: aborting");
      return;
    }

    setClicked(true);

    e.preventDefault();

    if (confirm("Confirm sync to salesforce?"))
      exportToSalesforce(id).then(onSuccess);
  };

const destroyHandler = (id, onSuccess) => (e) => {
  e.preventDefault();

  if (confirm(`Confirm Proposal(${id}) destroy?`))
    destroyProposal(id).then(onSuccess);
};

const inspectHandler = (inspect, type, id) => (e) => {
  e.preventDefault();
  inspect(type, id);
};

const InspectorButton = (props) => (
  <>
    <br />
    <button className="text-xs" {...props} />
  </>
);

const Restaurant = ({ proposal, inspect }) => {
  if (!proposal.restaurant) return null;

  return (
    <InspectorButton
      onClick={inspectHandler(inspect, "restaurant", proposal.restaurantId)}
    >
      {proposal.restaurant}
    </InspectorButton>
  );
};

const DeliveryZone = ({ proposal, inspect }) => {
  if (!proposal.deliveryZone) return null;

  return (
    <InspectorButton
      onClick={inspectHandler(inspect, "deliveryZone", proposal.deliveryZoneId)}
    >
      {proposal.deliveryZone}
    </InspectorButton>
  );
};

const EventLocations = ({ proposal, inspect }) => {
  if (!proposal.eventLocations) return null;

  return (
    <InspectorButton
      onClick={inspectHandler(
        inspect,
        "eventLocations",
        proposal.eventLocationIds,
      )}
    >
      {`${proposal.eventLocations.length} locations`}
    </InspectorButton>
  );
};

const PickupLocation = ({ proposal, inspect }) => {
  if (!proposal.pickupLocation) return null;

  return (
    <InspectorButton
      onClick={inspectHandler(
        inspect,
        "pickupLocation",
        proposal.pickupLocationId,
      )}
    >
      PU: {proposal.pickupLocation}
    </InspectorButton>
  );
};

const RunTimes = ({ proposal }) => (
  <>
    <br />
    <span>
      {showDay(`${proposal.runDate}T12:00:00`, true)} -{" "}
      {proposal.runAt ? showTime(proposal.runAt) : null}
    </span>
  </>
);

const Status = ({ proposal, inspect }) => (
  <InspectorButton onClick={inspectHandler(inspect, "status", proposal.status)}>
    ({proposal.status})
  </InspectorButton>
);

const Proposal = ({
  proposal,
  activeProposal,
  setActiveProposal,
  attribute,
  inspect,
  bump,
}) => {
  const [clicked, setClicked] = useState(false);

  const restaurantClass =
    attribute === "restaurant" &&
    activeProposal.restaurantId === proposal.restaurantId
      ? `bg-${COLORS.restaurant}-500`
      : "";

  const eventLocationClass =
    attribute === "eventLocation" &&
    activeProposal.eventLocationId === proposal.eventLocationId
      ? `bg-${COLORS.eventLocation}-500`
      : "";

  const weekDayClass =
    attribute === "weekDay" &&
    showWeekDay(activeProposal.runDate) === showWeekDay(proposal.runDate)
      ? `bg-${COLORS.weekDay}-500`
      : "";

  return (
    <td
      className={`w-50 text-xs ${restaurantClass} ${eventLocationClass} ${weekDayClass}`}
      onMouseEnter={() => setActiveProposal(proposal)}
      onMouseLeave={() => setActiveProposal({})}
    >
      <button
        className="btn p-1"
        onClick={() => inspect("proposal", proposal.id)}
      >
        <EyeIcon className="h-5 w-5 text-yellow-500 text-center" />
      </button>
      <button className="btn p-1" onClick={goToEdit(proposal.id)}>
        <PencilIcon className="h-5 w-5 text-blue-500 text-center" />
      </button>
      <button
        className="btn p-1"
        onClick={exportToSalesforceHandler(
          proposal.id,
          bump,
          clicked,
          setClicked,
        )}
      >
        <SwitchVerticalIcon
          className={
            "h-5 w-5 text-center " +
            (proposal.salesforceId ? " text-green-500" : " text-red-500")
          }
        />
      </button>
      {proposal.salesforceId ? null : (
        <button className="btn p-1" onClick={destroyHandler(proposal.id, bump)}>
          <TrashIcon className="h-5 w-5 text-red-500 text-center" />
        </button>
      )}
      <br />
      <Restaurant proposal={proposal} inspect={inspect} />

      <DeliveryZone proposal={proposal} inspect={inspect} />

      <EventLocations proposal={proposal} inspect={inspect} />

      <PickupLocation proposal={proposal} inspect={inspect} />

      <RunTimes proposal={proposal} />

      <Status proposal={proposal} inspect={inspect} />
    </td>
  );
};

export default Proposal;
