import Avatar from "@material-ui/core/Avatar";
// import Box from '@material-ui/core/Box';
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/Send";
import PropTypes from "prop-types";
import React from "react";
import timeAgo from "../helpers/ago"; // eslint-disable-line

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
  leftBubble: {
    border: "0.5px solid #D5E5EF",
    backgroundColor: "#EDF8FF",
    borderRadius: "10px",
    margin: "5px",
    padding: "10px",
    width: "unset",
    // display: "inline-block"
  },
  rightBubble: {
    border: "0.5px solid #D3D3D3",
    borderRadius: "10px",
    backgroundColor: "#F5F5F5",
    margin: "5px",
    padding: "10px",
    width: "unset",
  },
  leftItem: {
    justifyContent: "flex-start",
    width: "unset",
  },
  rightItem: {
    justifyContent: "flex-end",
    width: "unset",
  },
});

const ChatMessage = ({ body, status, from, customer, created_at }) => {
  // eslint-disable-line
  const classes = useStyles();

  const inbound = customer?.phone == from;

  // console.log("Phone", customer.phone, to)

  const direction = inbound ? "left" : "right";

  const statusText = ["delivered", "received"].includes(status) ? null : status;

  return (
    <ListItem
      key="1"
      className={inbound ? classes.leftItem : classes.rightItem}
    >
      <Grid
        container
        className={inbound ? classes.leftBubble : classes.rightBubble}
      >
        <Grid item xs={12}>
          <ListItemText
            align={direction}
            primary={body}
            secondary={inbound ? customer.name : "DwellSocial"}
          ></ListItemText>
        </Grid>
        <Grid item xs={12}>
          <ListItemText
            align={direction}
            primary={statusText}
            secondary={timeAgo(created_at)}
          ></ListItemText>
        </Grid>
      </Grid>
    </ListItem>
  );
};

ChatMessage.propTypes = {
  customer: PropTypes.object,
  body: PropTypes.any,
  status: PropTypes.any,
  from: PropTypes.any,
  to: PropTypes.any,
  created_at: PropTypes.any,
};

const Chat = ({ header, left, action, messages, user }) => {
  // eslint-disable-line
  const classes = useStyles();

  return (
    <div>
      {header ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" className="header-message">
              {header}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <Grid container component={Paper} className={classes.chatSection}>
        {left ? (
          <Grid item xs={3} className={classes.borderRight500}>
            <List>
              <ListItem button key="RemySharp">
                <ListItemIcon>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://material-ui.com/static/images/avatar/1.jpg"
                  />
                </ListItemIcon>
                <ListItemText primary="John Wick"></ListItemText>
              </ListItem>
            </List>
            <Divider />
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                id="outlined-basic-email"
                label="Search"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Divider />
            <List>
              <ListItem button key="RemySharp">
                <ListItemIcon>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://material-ui.com/static/images/avatar/1.jpg"
                  />
                </ListItemIcon>
                <ListItemText primary="Remy Sharp">Remy Sharp</ListItemText>
                <ListItemText secondary="online" align="right"></ListItemText>
              </ListItem>
              <ListItem button key="Alice">
                <ListItemIcon>
                  <Avatar
                    alt="Alice"
                    src="https://material-ui.com/static/images/avatar/3.jpg"
                  />
                </ListItemIcon>
                <ListItemText primary="Alice">Alice</ListItemText>
              </ListItem>
              <ListItem button key="CindyBaker">
                <ListItemIcon>
                  <Avatar
                    alt="Cindy Baker"
                    src="https://material-ui.com/static/images/avatar/2.jpg"
                  />
                </ListItemIcon>
                <ListItemText primary="Cindy Baker">Cindy Baker</ListItemText>
              </ListItem>
            </List>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={9}>
          <List className={classes.messageArea}>
            {messages.map((message) => (
              <ChatMessage key={message.id} {...message} customer={user} />
            ))}
          </List>
          <Divider />
          {action ? (
            <Grid container style={{ padding: "20px" }}>
              <Grid item xs={11}>
                <TextField
                  id="outlined-basic-email"
                  label="Type Something"
                  fullWidth
                />
              </Grid>
              <Grid xs={1} align="right">
                <Fab color="primary" aria-label="add">
                  <SendIcon />
                </Fab>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};
Chat.propTypes = {
  messages: PropTypes.array,
  header: PropTypes.any,
  left: PropTypes.any,
  action: PropTypes.any,
  user: PropTypes.any,
};

export default Chat;
