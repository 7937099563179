import { Container, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ProjectSearch from "./projectSearch";
import UserSearch from "./userSearch";

const Service = () => {
  const [url, setUrl] = useState();

  const setSearch = (type) => (id) => {
    setUrl({ project: "/events/", user: "/users/" }[type] + id);
  };

  if (url) {
    window.location.assign(url);
    return null;
  }

  return (
    <Container style={{ marginTop: "2rem" }}>
      <Typography variant="h2">Customer Service Tools</Typography>
      <Container
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <UserSearch onChange={setSearch("user")} />
        <Typography
          variant="h3"
          style={{ marginLeft: "1rem", marginRight: "1rem" }}
        >
          OR
        </Typography>
        <ProjectSearch onChange={setSearch("project")} />
      </Container>
    </Container>
  );
};

Service.propTypes = {
  match: PropTypes.object,
};
export default Service;
