import React from "react";

const SyncedFilter = ({ syncedFilter, setSyncedFilter, inspect }) => {
  const onChange = (e) => {
    setSyncedFilter(e.target.value);
  };

  return (
    <div className="ml-2">
      <h3>Synced Filter</h3>
      <select value={syncedFilter || ""} onChange={onChange}>
        <option value=""></option>
        <option value="synced">Synced</option>
        <option value="unsynced">Unsynced</option>
      </select>
    </div>
  );
};

export default SyncedFilter;
