import PencilIcon from "@material-ui/icons/Edit";
import ShopIcon from "@material-ui/icons/Shop";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Section from "../../backoffice/Section";
import { getMemberships } from "../../helpers/api";
import DataTable from "../calendar/DataTable";

const ProjectMembershipList = ({ memberships, ...props }) => {
  const rows = memberships.map((m) => [
    <a href={`/project_memberships/${m.id}/edit`}>
      <PencilIcon />
    </a>,
    <a href={`/events/${m.project_id}`}>{m.project_name}</a>,
    m.project_id,
    m.status,
    m.notes,
    m.order_notes,
    m.paid_fee,
    m["ordered?"],
    m.created_at,
  ]);

  const headers = [
    "Edit",
    "Event",
    "Event ID",
    "Status",
    "Internal Notes",
    "Order Notes",
    "Paid Fee?",
    "Ordered?",
    "Created",
  ];

  return <DataTable rows={rows} headers={headers} />;
};

ProjectMembershipList.propTypes = {
  selectedIds: PropTypes.array,
};

const Orders = ({ user }) => {
  const [memberships, setMemberships] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    if (user?.id) {
      getMemberships(user.id)
        .then(({ data }) => setMemberships(data))
        .catch((error) => setError(error));
    }
  }, [user?.id]); // eslint-disable-line

  if (!user) return "";

  return (
    <Section title="Orders" subtitle="" loaded={true} icon={ShopIcon}>
      <ProjectMembershipList memberships={memberships} />
    </Section>
  );
};

Orders.propTypes = {
  id: PropTypes.any,
  user: PropTypes.object,
};

export default Orders;
