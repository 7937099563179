import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";

require.context("images", true);

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");

// https://github.com/rails/rails/issues/24257#issuecomment-705373212
window.addEventListener("turbo:load", function () {
  Rails.refreshCSRFTokens();
});

Rails.start();

// https://github.com/reactjs/react-rails#event-handling
window.Turbo = Turbo;
ReactRailsUJS.detectEvents();

var skipFirstCall = false;
ReactRailsUJS.handleEvent("turbo:load", () => {
  skipFirstCall && ReactRailsUJS.handleMount();
  skipFirstCall = true;
});

ReactRailsUJS.handleEvent("turbo:before-render", ReactRailsUJS.handleUnmount);

delete window.Turbo;

ReactRailsUJS.useContext(componentRequireContext);
