import React from "react";

const StatusFilter = ({ statusFilter, setStatusFilter, inspect }) => {
  const onChange = (e) => {
    inspect("status", e.target.value);
    setStatusFilter(e.target.value);
  };

  return (
    <div className="ml-2">
      <h3>Status Filter</h3>
      <select value={statusFilter} onChange={onChange}>
        <option value=""></option>
        <option value="pending">Pending</option>
        <option value="accepted">Accepted</option>
        <option value="rejected">Rejected</option>
      </select>
    </div>
  );
};

export default StatusFilter;
