import React, { useState } from "react";

export default function CampaignLinkCreator({ eventIDs, handleSubmit, error }) {
  const [value, setValue] = useState("");

  const handleClick = () => {
    handleSubmit(value);
    setValue("");
  };

  return (
    <div>
      <dt className="group flex items-center">Campaign links</dt>
      <dd>
        <div>
          <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                onChange={(e) => setValue(e.target.value)}
                value={value}
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md text-xs border-gray-300"
                placeholder="utm_campaign=foo&utm_medium=bar"
              />
            </div>
            <button
              onClick={handleClick}
              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-xs font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <span>Create</span>
            </button>
          </div>
        </div>
        {error && <div className="text-red-500 text-xs mt-2">{error}</div>}
      </dd>
    </div>
  );
}
