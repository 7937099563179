import React from "react";

export const COLORS = {
  restaurant: "green",
  weekDay: "blue",
};

const ColorBox = ({ color, label }) => (
  <span
    className={`text-white bg-${color}-500 px-3 py-4 text-sm flex-0 font-semibold leading-tight m-4`}
  >
    {label}
  </span>
);

const Legend = ({ attribute, setAttribute }) => {
  const radioClasses =
    "form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer";

  const onChange = (e) => setAttribute(e.currentTarget.value);

  return (
    <div className="m-4">
      <div className="flex justify-center">
        <div className="form-check form-check-inline">
          <input
            className={radioClasses}
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="restaurant"
            onChange={onChange}
            checked={attribute === "restaurant"}
          />
          <label
            className="form-check-label inline-block text-gray-800"
            htmlFor="inlineRadio10"
          >
            {" "}
            <ColorBox color={COLORS.restaurant} label="Restaurant" />{" "}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className={radioClasses}
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            value="weekDay"
            onChange={onChange}
            checked={attribute === "weekDay"}
          />
          <label
            className="form-check-label inline-block text-gray-800 opacity-50"
            htmlFor="inlineRadio30"
          >
            <ColorBox color={COLORS.weekDay} label="WeekDay" />{" "}
          </label>
        </div>
      </div>
    </div>
  );
};

export default Legend;
