import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

const Metadata = ({ eventId, eventDate }) => (
  <>
    <View style={styles.project}>
      <Text style={styles.projectLabel}>Group ID:</Text>
      <Text style={styles.projectDate}>{eventId}</Text>
    </View>
    <View style={styles.projectDateContainer}>
      <Text style={styles.projectLabel}>Run Date: </Text>
      <Text style={styles.projectDate}>{eventDate}</Text>
    </View>
  </>
);

Metadata.propTypes = {
  eventId: PropTypes.any,
  eventDate: PropTypes.string,
};
export default Metadata;
