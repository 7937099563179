import { Text, View } from "@react-pdf/renderer";
import React from "react";
import styles from "./styles";

const company = "DwellSocial, Inc.";
const address = "3100 N. Concord Ct.";
const loc = "Northbrook, IL, 60062";
// const phone = "312-576-9681"
const email = "accounts@dwellsocial.com";

const Billable = () => (
  <View style={styles.billableContainer}>
    <Text style={styles.billableLabel}>Bill To:</Text>
    <Text>{company}</Text>
    <Text>{address}</Text>
    <Text>{loc}</Text>
    {/*<Text>{phone}</Text>*/}
    <Text>{email}</Text>
  </View>
);

export default Billable;
