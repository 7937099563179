import exporter from "./index";

// CSV EXPORT
const query = (project) => ({
  filter: {
    fields: ["customer", "item", "quantity", "notes", "price", "cost"],
    project_id: project.id,
  },
  pagination: { page: 1, perPage: 10000 },
  sort: { field: "created_at", order: "ASC" },
});

const membershipsExporter = exporter({
  filename: "orders",
  source: "line_items",
  fields: ["customer", "item", "quantity", "notes", "price", "cost"],
  labels: ["Customer", "Item", "Quantity", "Notes", "Price", "Cost"],
  query,
  transformer: (item) => ({
    ...item,
  }),
});

export const chitExporter = () => {};

export default membershipsExporter;
