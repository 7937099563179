import { Text, View } from "@react-pdf/renderer";
import React from "react";

import styles from "./styles";

const Header = () => (
  <View style={styles.headerContainer}>
    <Text style={styles.headerId}>Order ID</Text>
    <Text style={styles.headerSubtotal}>Food/Bev</Text>
    <Text style={styles.headerRefund}>Refund</Text>
    <Text style={styles.headerTax}>Tax</Text>
    <Text style={styles.headerTip}>Tip</Text>
    <Text style={styles.headerTip}>Service</Text>
    <Text style={styles.headerTotal}>Total</Text>
  </View>
);

export default Header;
