import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { toMoney } from "../../helpers";
import styles from "./styles";
const Row = ({ label, value, bold }) => (
  <View style={styles.footerRow}>
    <Text style={styles.footerLabel}>{label}</Text>
    <Text style={styles[bold ? "footerTotal" : "footerValue"]}>{value}</Text>
  </View>
);

Row.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  bold: PropTypes.bool,
};

const Summary = ({ totalFees, totalDue }) => (
  <>
    <Row label="TOTAL FEES" value={toMoney(totalFees)} />
    <Row label="TOTAL DUE" value={toMoney(totalDue)} bold={true} />
  </>
);

Summary.propTypes = {
  totalFees: PropTypes.any,
  totalDue: PropTypes.any,
};

export default Summary;
