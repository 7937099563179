import { Document, Page, PDFDownloadLink } from "@react-pdf/renderer"; // eslint-disable-line
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import loadInvoice from "./data";
import styles from "./styles";

import Billable from "./Billable";
import Footer from "./Footer";
import Header from "./Header";
import Metadata from "./Metadata";
import OrdersTable from "./OrdersTable";

export const camelize = (word) =>
  word
    .toLowerCase()
    .replace(/([_][a-z])/g, (group) => group.toUpperCase().replace("_", ""));

export const deepCamelizeKeys = (obj) =>
  Object.entries(obj).reduce(
    (ac, kv) => ({
      ...ac,
      [camelize(kv[0])]: Array.isArray(kv[1])
        ? kv[1].map(deepCamelizeKeys)
        : kv[1] === Object(kv[1])
          ? deepCamelizeKeys(kv[1])
          : kv[1],
    }),
    {},
  );

// Create Document Component
export const InvoicePDF = ({ invoice }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header title={invoice.eventName} />
      <Metadata eventId={invoice.eventId} eventDate={invoice.eventDate} />
      <Billable />
      <OrdersTable invoice={invoice} />
      <Footer />
    </Page>
  </Document>
);

InvoicePDF.propTypes = {
  invoice: PropTypes.object,
};

const Invoice = ({ style, id, status, name }) => {
  const [invoice, setInvoice] = useState();

  const setError = (err) => console.log("Order Loading failed", err);

  const event = { id, status };

  const setNormalizedInvoice = (inv) => setInvoice(deepCamelizeKeys(inv));

  useEffect(() => {
    if (event.id) loadInvoice(event).then(setNormalizedInvoice).catch(setError);
  }, [event.id]); // eslint-disable-line

  if (!event.id || !invoice) return null;

  return (
    <PDFDownloadLink
      document={<InvoicePDF invoice={invoice} event={event} />}
      fileName={`invoice-${name || id}.pdf`}
      style={style}
      className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-47 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
    >
      {({ loading }) =>
        loading ? "Loading document..." : `INVOICE-${name || id}.PDF`
      }
    </PDFDownloadLink>
  );
};

Invoice.propTypes = {
  style: PropTypes.object,
  id: PropTypes.any,
  status: PropTypes.string,
};

export default Invoice;
