import { StyleSheet } from "@react-pdf/renderer";

export const dsGreen = "#33AA44";

export default StyleSheet.create({
  // outer page
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 300,
    height: 157,
    marginLeft: "auto",
    marginRight: "auto",
  },

  // header component
  header: {
    flexDirection: "row",
    marginTop: 24,
  },
  headerText: {
    color: dsGreen,
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },

  // metadata component
  project: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  },
  projectDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  projectDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  projectLabel: {
    width: 60,
  },

  // billable component
  billableContainer: {
    marginTop: 36,
  },
  billableLable: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },

  // footer component
  footer: {
    flexDirection: "row",
    marginTop: 12,
  },
  footerTitle: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
});
