import { View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import Order from "./Order";

const Orders = ({ orders }) => (
  <View>
    {orders.map((order) => (
      <Order {...order} key={order.id} />
    ))}
  </View>
);

Orders.propTypes = {
  orders: PropTypes.array,
};
export default Orders;
