import axios from "axios";
import dayjs from "dayjs";

/////////
/// Generic wrappers
/////////

export const getList = (source, query) => {
  return axios.get(source, { params: query });
};

/////////
// Assets
/////////
export const getAvatarUrl = (id) =>
  new Promise((resolve, reject) => {
    dataProvider
      .getOne("avatars", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

/////////////
// Customers
/////////////
export const fromCustomerBalanceResponse = (params) => ({
  ...params,
  credit_balance: -params.credit_balance,
});

export const toCustomerBalanceParams = (params) => ({
  ...params,
  amount: -params.amount,
});

// The API/backend treats credits as negative, debits as positive, a'la Stripe
export const getCustomerBalance = (id) =>
  axios
    .get(`/api/admin/users/${id}/credit_balance`)
    .then(({ data }) => ({ data: fromCustomerBalanceResponse(data) }));

export const getEmails = (id) =>
  axios.get("/api/admin/emails", {
    params: {
      filter: JSON.stringify({ user_id: id }),
      sort: JSON.stringify({}),
      pagination: JSON.stringify({ page: 1, perPage: 10 }),
    },
  });

export const getTickets = (id) =>
  axios.get("/api/admin/tickets", {
    params: {
      filter: JSON.stringify({ user_id: id }),
      sort: JSON.stringify({}),
      pagination: JSON.stringify({ page: 1, perPage: 10 }),
    },
  });

export const getTexts = (id) =>
  axios.get("/api/admin/texts", {
    params: {
      filter: JSON.stringify({ user_id: id }),
      sort: JSON.stringify({}),
      pagination: JSON.stringify({ page: 1, perPage: 10 }),
    },
  });

export const getUser = (id) => axios.get(`/api/admin/users/${id}`);
/////////
// Orders
/////////
export const cancelOrder = (id) =>
  axios
    .get(`/api/admin/project_memberships/${id}/cancel`, { method: "PUT" })
    .then((response) => ({ data: response.json }))
    .then(({ data }) => data);

export const updateMembership = (id, body) =>
  httpClient(`/api/admin/project_memberships/${id}`, { method: "PUT", body })
    .then((response) => ({ data: response.json }))
    .then(({ data }) => data);

export const getMemberships = (id) =>
  axios.get("/api/admin/project_memberships", {
    params: {
      filter: JSON.stringify({ user_id: id }),
      sort: JSON.stringify({}),
      pagination: JSON.stringify({ page: 1, perPage: 10 }),
    },
  });

export const getLineItems = (project_id) =>
  axios.get("/api/admin/line_items", {
    params: {
      filter: JSON.stringify({
        fields: [
          "id",
          "project_membership_id",
          "customer",
          "item",
          "quantity",
          "notes",
          "price",
          "cost",
          "d2d",
          "order_d2d",
          "order_notes",
          "pickup_time",
          "options",
          "customer_address",
          "customer_phone",
        ],
        project_id,
        ordered: true,
      }),
      pagination: JSON.stringify({ page: 1, perPage: Config.BULK_PER_PAGE }),
      sort: JSON.stringify({ field: "created_at", order: "ASC" }),
    },
  });

const AdminOffset = 5; // assumes central
export const normalizeStartDate = (date) =>
  date &&
  dayjs(date)
    .startOf("day")
    .add(AdminOffset, "h")
    .format("YYYY-MM-DD HH:mm:ss");

export const normalizeEndDate = (date) =>
  date &&
  dayjs(date).endOf("day").add(AdminOffset, "h").format("YYYY-MM-DD HH:mm:ss");

export const getInvoiceableEventIds = (start_date, end_date) =>
  getList("/reports/invoiceable_events.json", {
    start_date: normalizeStartDate(start_date),
    end_date: normalizeEndDate(end_date),
  }).then(({ data }) => data);

export const getAllEventIds = (start_date, end_date) =>
  getList("/reports/all_events.json", {
    start_date: normalizeStartDate(start_date),
    end_date: normalizeEndDate(end_date),
  }).then(({ data }) => data);

export const getChits = (start_date, end_date, per_page) =>
  axios
    .get("/reports/chits.json", {
      params: {
        start_date: normalizeStartDate(start_date),
        end_date: normalizeEndDate(end_date),
        per_page,
      },
    })
    .then(({ data }) => data);

export const getInvoice = (id) => axios.get(`/api/admin/invoices/${id}`);

/////////////////
// Generic Search
/////////////////

export const search = (url, q, { fields, filters, sort, queryTransform }) =>
  axios.get(url, {
    params: {
      filter: JSON.stringify({
        q: queryTransform ? queryTransform(q) : q,
        fields,
        ...(filters ? filters : {}),
      }),
      ...(sort ? { sort: JSON.stringify(sort) } : {}),
    },
  });
