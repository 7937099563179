import SmsIcon from "@material-ui/icons/Sms";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Chat from "../../backoffice/Chat";
import Section from "../../backoffice/Section";
import { getTexts } from "../../helpers/api";

const Texts = ({ id, user }) => {
  const [texts, setTexts] = useState([]);
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (id) {
      setLoaded(false);
      getTexts(id)
        .then(
          ({ data }) => console.log("setting texts", data) || setTexts(data),
        )
        .catch((error) => setError(error))
        .finally(() => setLoaded(true));
    }
  }, [id]); // eslint-disable-line

  return (
    <Section title="Texts" subtitle="Twilio" icon={SmsIcon} loaded={loaded}>
      <Chat messages={texts} user={user} />
      {error ? <Error error={error || ""} /> : ""}
    </Section>
  );
};

Texts.propTypes = {
  id: PropTypes.any,
  user: PropTypes.object,
};

export default Texts;
