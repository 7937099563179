import { getList } from "../../../helpers/api";

import { unparse as convertToCSV } from "papaparse/papaparse.min";
// import { GET_LIST } from "react-admin"
import { sendCSV } from "../sendCSV";

export const getAll = (source, query) =>
  getList(source, query)
    .then((response) => response.data)
    .catch((err) => console.log("Get All Error", source, query, err) || []);

const applyTransformer =
  ({ transformer }) =>
  (items) =>
    Promise.all(
      items.map((item) =>
        Promise.resolve(transformer ? transformer(item) : item),
      ),
    );

const toRow = (options) => (item) =>
  options.fields.reduce(
    (row, field, i) => ({ ...row, [options.labels[i]]: item[field] }),
    {},
  );

const toRows = (options) => (items) =>
  items.filter((i) => i).map(toRow(options));

const toCSV =
  ({ labels }) =>
  (data) =>
    convertToCSV({ data, labels });

const csvOut =
  ({ filename }) =>
  (csv) =>
    sendCSV(csv, filename);

const exporter =
  ({ fields, filename, labels, query, source, transformer }) =>
  (data, onComplete) =>
  (event) => {
    event.preventDefault();
    // console.log("Getting All", data, query(data), source);
    return getAll(source, query(data))
      .then(applyTransformer({ transformer }))
      .then(toRows({ fields, labels }))
      .then(toCSV({ labels }))
      .then(csvOut({ filename }))
      .then(() => (onComplete ? onComplete() : null));
  };

export default exporter;
