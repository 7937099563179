import React, { useEffect, useState } from "react";
import {
  getEventLocations,
  getProposalsForMonth,
  getRestaurants,
} from "./data";
import { showDate } from "./dates";
import Inspector from "./Inspector";
import MonthlyCalendar from "./MonthlyCalendar";
import MonthPicker from "./MonthPicker";
import MonthSummary from "./MonthSummary";

const dateToUTC = (dateString) => {
  const parts = dateString.split("-");
  return Date.UTC(parts[0], parts[1] - 1, parts[2], 12);
};

const Calendar = () => {
  const params = new URLSearchParams(window.location.search);

  const initialMonth = params.get("month");

  const [proposals, setProposals] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [eventLocations, setEventLocations] = useState([]);

  const [month, setMonth] = useState(
    initialMonth ? new Date(dateToUTC(initialMonth)) : new Date(),
  );

  const [type, setType] = useState();
  const [id, setId] = useState();
  const [version, setVersion] = useState(1);

  const bump = () => setVersion(version + 1);

  const inspect = (type, id) => {
    setType(type);
    setId(id);
  };

  useEffect(() => {
    getProposalsForMonth(month).then((result) => setProposals(result.data));
  }, [showDate(month), version]);

  useEffect(() => {
    getRestaurants().then((result) =>
      setRestaurants(
        result.data
          .filter((r) => r.isRestaurant)
          .sort((a, b) => (a.businessName > b.businessName ? 1 : -1)),
      ),
    );
  }, [1]);

  useEffect(() => {
    getEventLocations().then((result) =>
      setEventLocations(result.data.sort((a, b) => (a.name > b.name ? 1 : -1))),
    );
  }, [1]);

  return (
    <>
      <MonthPicker month={month} setMonth={setMonth} disabled={false} />
      <div className="grid grid-cols-2 gap-4">
        <MonthSummary month={month} proposals={proposals} />
        <Inspector
          restaurants={restaurants}
          proposals={proposals}
          type={type}
          id={id}
        />
      </div>
      <hr />
      <MonthlyCalendar
        month={month}
        restaurants={restaurants}
        proposals={proposals}
        inspect={inspect}
        bump={bump}
        eventLocations={eventLocations}
      />
    </>
  );
};

export default Calendar;
