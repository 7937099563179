const timeZone = "America/Chicago";

// Date Helpers for RunAt
const quantizeMonth = (month, start) =>
  new Date(
    month.getFullYear(),
    month.getMonth() + (start ? 0 : 1),
    start ? 1 : 0,
  );

export const showDate = (date) =>
  new Date(date).toLocaleString("en-us", {
    timeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

export const showDay = (date) =>
  new Date(date).toLocaleString("en-us", {
    timeZone,
    month: "numeric",
    day: "numeric",
  });

export const showWeekDay = (date) =>
  new Date(date).toLocaleString("en-us", {
    timeZone,
    weekday: "short",
  });

export const showTime = (date) =>
  new Date(date).toLocaleString("en-us", {
    timeZone,
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

const dateKey = (date) => {
  const [year, month, day] = date.split("-");
  const parsed = new Date(year, month - 1, day, 12);
  return showDay(parsed);
};

const weekDateKey = (date) => {
  const [year, month, day] = date.split("-");
  const parsed = new Date(year, month - 1, day, 12);
  return showWeekDay(parsed);
};

export const groupByDay = (proposals) =>
  proposals.reduce((hash, obj) => {
    const key = dateKey(obj.runDate);

    return { ...hash, [key]: (hash[key] || []).concat(obj) };
  }, {});

export const groupByWeekDay = (proposals) =>
  proposals.reduce((hash, obj) => {
    const key = weekDateKey(obj.runDate);

    return { ...hash, [key]: (hash[key] || []).concat(obj) };
  }, {});

export const daysOfMonth = (date, filter) => {
  const start = quantizeMonth(date, true);

  const end = quantizeMonth(date, false);

  const days = [];

  for (var day = start; day <= end; day.setDate(day.getDate() + 1)) {
    if (filter && !filter(day)) {
      continue;
    }

    days.push(new Date(day));
  }

  return days;
};

export const schedulableDaysOfMonth = (date) => {
  return daysOfMonth(date, (day) => !showWeekDay(day).match(/mon/i));
};
