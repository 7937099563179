import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getEventLocation, getRestaurant } from "./data";
import DataContainer from "./DataContainer";
import DataPoint from "./DataPoint";
import { showDay, showTime } from "./dates";

const EventLink = ({ salesforceId }) =>
  !salesforceId ? null : (
    <a
      href={`https://dwellsocial.lightning.force.com/lightning/r/Event_Group__c/${salesforceId}/view`}
      target="_blank"
    >
      {salesforceId}
    </a>
  );

const AccountLink = ({ salesforceId }) =>
  !salesforceId ? null : (
    <a
      href={`https://dwellsocial.lightning.force.com/lightning/r/Account/${salesforceId}/view`}
      target="_blank"
    >
      {salesforceId}
    </a>
  );

const Proposal = ({ proposals, id }) => {
  const proposal = proposals.find((p) => p.id === id);

  return (
    <>
      <h1>Proposal Summary</h1>
      <DataContainer>
        <DataPoint label="ID" value={id} />
        <DataPoint label="Restaurant" value={proposal.restaurant} />
        <DataPoint label="DeliveryZone" value={proposal.deliveryZone} />
        <DataPoint label="Event Locations" value={proposal.eventLocations} />
        <DataPoint label="Pickup Location" value={proposal.pickupLocation} />
      </DataContainer>
      <DataContainer>
        <DataPoint
          label="Run Date"
          value={showDay(`${proposal.runDate}T12:00:00`)}
        />
        <DataPoint
          label="Run At"
          value={proposal.runAt ? showTime(proposal.runAt) : null}
        />
        <DataPoint
          label="Produced Event ID"
          value={proposal.eventId || "n/a"}
        />
        <DataPoint label="SalesforceId">
          <EventLink salesforceId={proposal.salesforceId} />
        </DataPoint>
        <DataPoint label="Status" value={proposal.status} />
        {proposal.status !== "rejected" ? null : (
          <p className="bg-red-300">{proposal.rejectionReason}</p>
        )}
      </DataContainer>
    </>
  );
};

const Restaurant = ({ proposals, restaurants, id }) => {
  const [restaurant, setRestaurant] = useState();

  useEffect(() => {
    if (!id) {
      setRestaurant(null);
    } else {
      getRestaurant(id).then(({ data }) => setRestaurant(data.restaurant));
    }
  }, [id]);

  if (!restaurant) return null;

  const proposalsForRestaurant = proposals.filter(
    (p) => `${p.restaurantId}` === `${id}`,
  );

  return (
    <>
      <h1>Restaurant Summary</h1>
      <div className="flex items-center">
        <DataPoint label="ID" value={id} />
        <DataPoint label="Restaurant" value={restaurant.businessName} />
        <DataPoint label="SalesforceId">
          <AccountLink salesforceId={restaurant.salesforceId} />
        </DataPoint>
        <DataPoint
          label="Proposal Count"
          value={proposalsForRestaurant.length || 0}
        />
      </div>
    </>
  );
};

const DeliveryZone = ({ proposals, id }) => {
  const deliveryZoneName = proposals.find(
    (p) => p.deliveryZoneId === id,
  ).deliveryZone;

  const proposalsForDeliveryZone = proposals.filter(
    (p) => p.deliveryZoneId === id,
  );

  return (
    <>
      <h1>DeliveryZone Summary</h1>
      <div className="flex items-center">
        <DataPoint label="ID" value={id} />
        <DataPoint label="name" value={deliveryZone} />
        <DataPoint
          label="Proposal Count"
          value={proposalsForDeliveryZone.length}
        />
      </div>
    </>
  );
};

const EventLocations = ({ proposals, id }) => {
  const [eventLocationName, setEventLocationName] = useState();

  useEffect(() => {
    if (!id) {
      setEventLocationName(null);
    } else {
      getEventLocation(id).then(({ data }) =>
        setEventLocationName(data.event_location.name),
      );
    }
  }, [id]);

  if (!eventLocationName) return null;

  const proposalsForEventLocation = proposals.filter((p) => {
    return p.eventLocationIds.includes(Number(id));
  });

  return (
    <>
      <h1>EventLocation Summary</h1>
      <div className="flex items-center">
        <DataPoint label="ID" value={id} />
        <DataPoint label="Event Location" value={eventLocationName} />
        <DataPoint
          label="Proposal Count"
          value={proposalsForEventLocation.length}
        />
      </div>
    </>
  );
};

const PickupLocation = ({ proposals, id }) => {
  const pickupLocationName = proposals.find(
    (p) => p.pickupLocationId === id,
  ).pickupLocation;

  const proposalsForPickupLocation = proposals.filter(
    (p) => p.pickupLocationId === id,
  );

  return (
    <>
      <h1>PickupLocation Summary</h1>
      <div className="flex items-center">
        <DataPoint label="ID" value={id} />
        <DataPoint label="Pickup Location" value={pickupLocationName} />
        <DataPoint
          label="Proposal Count"
          value={proposalsForPickupLocation.length}
        />
      </div>
    </>
  );
};

const Status = ({ proposals, id }) => {
  const proposalsForStatus = proposals.filter((p) => p.status === id);

  return (
    <>
      <h1>Status Summary</h1>
      <div className="flex items-center">
        <DataPoint label="ID" value={id} />
        <DataPoint label="Proposal Count" value={proposalsForStatus.length} />
      </div>
    </>
  );
};

const renderInspector = (proposals, restaurants, type, id) => {
  switch (type) {
    case "proposal":
      return <Proposal proposals={proposals} id={id} />;
    case "restaurant":
      return (
        <Restaurant restaurants={restaurants} proposals={proposals} id={id} />
      );
    case "deliveryZone":
      return <DeliveryZone proposals={proposals} id={id} />;
    case "eventLocations":
      return <EventLocations proposals={proposals} id={id} />;
    case "pickupLocation":
      return <PickupLocation proposals={proposals} id={id} />;
    case "status":
      return <Status proposals={proposals} id={id} />;
    default:
      return null;
  }
};

const Inspector = ({ type, id, proposals, restaurants }) => {
  return (
    <div className="well">
      <div className="well-header">
        <div className="flex items-center">
          <div className="flex-grow">
            <h2>Inspector</h2>
          </div>
        </div>
      </div>

      <div className="well-body">
        {renderInspector(proposals, restaurants, type, id)}
      </div>
    </div>
  );
};

Inspector.propTypes = {
  month: PropTypes.instanceOf(Date),
  type: PropTypes.string,
  id: PropTypes.any,
};

export default Inspector;
