import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import ProductOption from "./ProductOption";

type OptionType = {
  id?: number;
  draggableId?: string;
  name: string;
  position?: number;
  status: string;
  startAt?: Date;
  endAt?: Date;
  choices: {
    id?: number;
    name: string;
    startAt?: Date;
    endAt?: Date;
    status: string;
    position?: number;
  }[];
};

type ProductOptionGroupProps = {
  options: OptionType[];
  cloneableOptions: {
    name: string;
    option: string;
    choices: string[];
  }[];
};

const initializeLocalOptions = (options): OptionType[] =>
  options.map((option) => ({ ...option, draggableId: uuidv4() }));

const ProductOptionGroup = ({
  options,
  cloneableOptions,
}: ProductOptionGroupProps) => {
  const [localOptions, setLocalOptions] = useState(
    initializeLocalOptions(options),
  );
  const [selectedCloneableOption, setSelectedCloneableOption] = useState(0);

  const addOption = (e: React.FormEvent) => {
    e.preventDefault();

    const newOption = {
      name: "",
      choices: [],
      draggableId: uuidv4(),
      position: localOptions.length + 1,
    };

    setLocalOptions([...localOptions, newOption]);
  };

  const cloneOption = (e: React.FormEvent) => {
    e.preventDefault();

    const newOption = {
      name: cloneableOptions[selectedCloneableOption].option,
      draggableId: uuidv4(),
      position: localOptions.length + 1,
      choices: cloneableOptions[selectedCloneableOption].choices.map(
        (choice, i) => ({
          name: choice,
          position: i,
          draggableId: uuidv4(),
        }),
      ),
    };

    setLocalOptions([...localOptions, newOption]);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return; // dropped outside the list
    } else if (destination.index === source.index) {
      return; // dropped into the same location
    } else {
      const moved = localOptions.find((_, i) => i === source.index);
      const remaining = localOptions.filter((_, i) => i !== source.index);
      const newLocalOptions = [
        ...remaining.slice(0, destination.index),
        moved,
        ...remaining.slice(destination.index),
      ].map((o, i) => ({ ...o, position: i + 1 }));

      setLocalOptions(newLocalOptions);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="productOptions">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            {localOptions.map((option, i) => (
              <ProductOption
                option={option}
                key={option.draggableId}
                index={i}
              />
            ))}
            {provided.placeholder}
            <div className="flex items-center">
              <div className="flex-none">
                <button className="btn-secondary mr-2" onClick={addOption}>
                  New Option
                </button>
              </div>
              <div className="flex-none">
                <button className="btn-secondary mr-2" onClick={cloneOption}>
                  Clone:
                </button>
              </div>
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={selectedCloneableOption}
                onChange={(e) =>
                  setSelectedCloneableOption(parseInt(e.target.value))
                }
              >
                {cloneableOptions.map((option, i) => (
                  <option value={i} key={i}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductOptionGroup;
