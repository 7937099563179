import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
// import Turbo from "turbo";

const DashboardDatePicker = ({ dateString, basePath }) => {
  const selectedDate = dateString ? new Date(`${dateString}:06:00`) : null;
  const dateToString = (date) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const handleChange = (date) =>
    window.Turbo.visit(`${basePath}?date=${dateToString(date)}`);

  const Button = forwardRef(({ onClick }, ref) => (
    <button className="btn-tertiary" onClick={onClick} ref={ref}>
      Select Date
    </button>
  ));

  return (
    <DatePicker
      onChange={handleChange}
      customInput={<Button />}
      selected={selectedDate}
    />
  );
};

export default DashboardDatePicker;
