// Derived from:
// https://gist.github.com/mage3k/e4b0eb24d4a19b28f4f24aee8a194107
// Epochs
const epochs = [
  ["year", 31536000],
  ["month", 2592000],
  ["day", 86400],
  ["hour", 3600],
  ["minute", 60],
  ["second", 1],
];

// Get duration
const getDuration = (timeAgoInSeconds) => {
  for (let [name, seconds] of epochs) {
    const interval = Math.floor(timeAgoInSeconds / seconds);

    if (interval >= 1) {
      return {
        interval: interval,
        epoch: name,
      };
    }
  }
};

// Calculate
export const timeAgo = (date) => {
  const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
  const { interval, epoch } = getDuration(timeAgoInSeconds);
  const suffix = interval === 1 ? "" : "s";

  return `${interval} ${epoch}${suffix} ago`;
};

export const splitDate = (object, field) => {
  const date = object[field];
  if (!date) return [];

  const dt = new Date(Date.parse(date));
  const pad = (val) => (`${val}`.length === 1 ? `0${val}` : `${val}`);

  const get = (obj) => (field) => obj[`get${field}`]();

  return [
    `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`,

    ["Hours", "Minutes", "Seconds"].map(get(dt)).map(pad).join(":"),
  ];
};

export default timeAgo;
